import { MissedAuditRow } from "./ReportingRows/missed-audit-row";
import { NonComplianceRow } from "./ReportingRows/non-compliance-row";

export class ReportData{
    public ReportID:number = -1;
    public NonComplianceRows:NonComplianceRow[] = [];
    public MissedAuditRows:MissedAuditRow[]=[];
    public ExportSummary:boolean = false;
    constructor(values: Object = {}){
        Object.assign(this, values);
        if (values.hasOwnProperty('NonComplianceRows')) {
            this.NonComplianceRows = [];
            ((values as ReportData)['NonComplianceRows'] as NonComplianceRow[]).forEach(element => {
                this.NonComplianceRows.push(new NonComplianceRow(element));
            });
        }
        if (values.hasOwnProperty('MissedAuditRows')) {
            this.MissedAuditRows = [];
            ((values as ReportData)['MissedAuditRows'] as MissedAuditRow[]).forEach(element => {
                this.MissedAuditRows.push(new MissedAuditRow(element));
            });
        }
    }
}