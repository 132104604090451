import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { UtilService } from './util.service';
import { catchError, EMPTY, map, Observable, of, tap } from 'rxjs';
import { LoginRequest } from '../models/login-request';
import { LoginResponse } from '../models/login-response';
import { PasswordValidResult } from '../interfaces/password-valid-result';
import { BasicResult } from '../models/basic-result';
import { ProgramFeatures } from '../models/UserManagement/program-features';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private localStorageService: LocalStorageService,
    private httpClient: HttpClient,
    private utils: UtilService
  ) {}

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    observe: 'response' as 'response',
    responseType: 'json' as const,
    withCredentials: true,
  };

  login(username: string, password: string) {
    let req = new LoginRequest({'Username':username,'Password':password});

    return this.httpClient
      .post<LoginResponse>(
        `${environment.apiUrl}userauth/login`,
        req,
        this.httpOptions
      )
      .pipe(
        tap((res) => {
          if (!res.body?.IsError) {
            this.localStorageService.setData(1, {
              LoggedIn: true,
              Username: username,
            });
          }
        })
      );
  }

  resetPassword(email: string) {
    let req = { Email: email };
    return this.httpClient.post<BasicResult>(
      `${environment.apiUrl}userauth/resetrequest`,
      req,
      this.httpOptions
    );
  }

  resetPasswordWithToken(token:string, newPassword:string){
    let req = {Token: token, Password: newPassword};
    return this.httpClient.post<BasicResult>(
      `${environment.apiUrl}userauth/resetwithtoken`,
      req,
      this.httpOptions
    );
  }

  isLoggedIn(){
    return this.httpClient
      .get(`${environment.apiUrl}userauth/isloggedin`, this.httpOptions).pipe(
        map(res => {
          return res;
        })
      );
  }

  isLoggedInOffline() {
    let loggedin: boolean = false;
    this.localStorageService.loadData(1);
    this.localStorageService.observables.localRef
      .find((e) => {
        return e.ID == 'userSettings';
      })!
      .Obs.pipe(
        tap((d) => {
          if (d != null) loggedin = d.LoggedIn;
        })
      )
      .subscribe();
    return loggedin;
  }

  /*signoutx() {
    this.localStorageService.clearData(1);
    return this.httpClient
      .get(`${environment.apiUrl}userauth/signout`, this.httpOptions)
      .subscribe();
  }*/
  signout(){
    return this.httpClient.get<BasicResult>(`${environment.apiUrl}userauth/signout`, this.httpOptions);
  }

  validPassword(
    newPassword: string,
    confirmPassword: string
  ): PasswordValidResult {
    let res = {
      Valid: true,
      NewPasswordErrorMessage: '',
      ConfirmPasswordErrorMessage: '',
    };

    if (newPassword.length < 8) {
      res.NewPasswordErrorMessage =
        'Password must be at least eight characters.';
      res.Valid = false;
    }

    if (newPassword != confirmPassword) {
      res.Valid = false;
      res.ConfirmPasswordErrorMessage = 'Both passwords must match.';
    }

    if (
      !this.utils.stringHasLowerCase(newPassword) ||
      !this.utils.stringHasUpperCase(newPassword) ||
      !this.utils.stringHasNumber(newPassword) ||
      !this.utils.stringHasSpecialCharacter(newPassword)
    ) {
      res.Valid = false;
      res.NewPasswordErrorMessage =
        'Password must have at least one upper case character, one lower case character, one number and one symbol.';
    }

    return res;
  }
  getUserName() {
    this.localStorageService.loadData(1);
    return this.localStorageService.observables.localRef.find((e) => {
      return e.ID == 'userSettings';
    })!.Obs;
  }
  
  getFeaturePermissions():Observable<ProgramFeatures>{
    return this.httpClient.get<ProgramFeatures>(`${environment.apiUrl}userauth/featurepermissions`, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }

  /*changePassword(oldPassword:string, newPassword: string){
    let req = {OldPassword: oldPassword, NewPassword: newPassword};
    return this.httpClient.post<ChangePassResponse>(`${environment.apiUrl}login/changepass`, req, this.httpOptions);
  }*/
}
