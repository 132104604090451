import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ReportResult } from '../models/Reports/report-results';
import { ReportRequest } from '../models/Reports/report-request';
import { environment } from 'src/environments/environment';
import { ReportFiltersResult } from '../models/Reports/report-filters-result';
import { BasicResult } from '../models/basic-result';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(private httpClient: HttpClient) { }
    httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
      responseType: 'json' as const,
      withCredentials: true,
    };

    getReport(reportRequest:ReportRequest): Observable<ReportResult>{
      return this.httpClient.post<ReportResult>(`${environment.apiUrl}reports/getReport`, reportRequest, this.httpOptions).pipe(
        map(res => {
          return res.body!;
        })
      );
    }
    getReportFilters():Observable<ReportFiltersResult>{
      return this.httpClient.get<ReportFiltersResult>(`${environment.apiUrl}reports/getreportfilters`, this.httpOptions).pipe(
        map(res => {
          return res.body!;
        })
      );
    }
    hasReportAccess():Observable<BasicResult>{
      return this.httpClient.get<BasicResult>(`${environment.apiUrl}reports/hasreportaccess`, this.httpOptions).pipe(
        map(res => {
          return res.body!;
        })
      );
    }
}
