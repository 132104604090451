import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { AuditLocation } from 'src/app/core-module/models/Locations/audit-location';
import { FeaturesList } from 'src/app/core-module/models/Locations/features-list';
import { LocationHolder } from 'src/app/core-module/models/Locations/location-holder';
import { LocationResult } from 'src/app/core-module/models/Locations/location-result';
import { UserManagementResult } from 'src/app/core-module/models/UserManagement/user-management-result';
import { UserPermissionsRequest } from 'src/app/core-module/models/UserManagement/user-permissions-request';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { ReferenceService } from 'src/app/core-module/services/reference.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';

@Component({
  selector: 'app-existing-users',
  templateUrl: './existing-users.component.html',
  styleUrl: './existing-users.component.scss'
})
export class ExistingUsersComponent {

  //location selection
  public originalData: AuditLocation[] = [];
  public minLevelID: number;
  public minSeq: number;
  public possibleLocations: AuditLocation[] = [];
  public locationsReady: boolean = false;
  public topLevel: boolean = true;

  public canStart: boolean = true;

  public isLocationSelected: boolean = false;
  public showLocation: boolean = true;

  public selectedLocation: AuditLocation = new AuditLocation();
  public loadedLocation: AuditLocation = new AuditLocation();

  public showPermissions: boolean = false;
  public includeSubLocations: boolean = false;
  public collapseSearch: boolean = false;

  public mostRecentRequest: UserPermissionsRequest = new UserPermissionsRequest();

  public filterUser: string = "";
  public initialLoadComplete: boolean = false;

  result: UserManagementResult;
  minLevel: number = 0;
  maxLevel: number = 0;
  unsavedChanges: boolean = false;
  saveStarted: boolean = false;
  isGroup: boolean = false;
  loadComplete: boolean = false;



  tableData: any[] = [];
  filteredData: any[] = [];

  constructor(private UMS: UserManagementService, private router: Router, private toastr: ToastrService) {
    if (this.router.url == '/settings/users/existinggroups') {
      this.isGroup = true;

    }
    this.getValidLocations();

  }
  ngOnInit() {

  }
  getValidLocations() {
    this.UMS.getUserManagementLocations().pipe(tap(a => { this.originalData = a, this.setMinLevel(), this.initialList(); })).subscribe()
  }
  setMinLevel() {
    this.minSeq = this.originalData[0].LocationLevelSeq;
    this.minLevelID = this.originalData[0].LocationLevelID;
    this.originalData.forEach(e => {
      if (e.LocationLevelSeq < this.minSeq) {
        this.minSeq = e.LocationLevelSeq;
        this.minLevelID = e.LocationLevelID;
      }
    })
  }
  initialList() {
    this.possibleLocations = [];
    this.topLevel = true;

    //this.possibleLocations.push(new UserLocation({HasPermission:false,LocationName:"Please select...",LocationID:-1}))
    //this.selectedLocation = this.possibleLocations[0];
    this.originalData.forEach(e => {
      if (e.LocationLevelID == this.minLevelID) {
        this.possibleLocations.push(new AuditLocation(e));
      }
      this.locationsReady = true;
    })

  }


  setAllLocations() {
    var AllLocation = new AuditLocation();
    this.tableData = [];
    this.result.Users.forEach(x => {
      var FL = new FeaturesList();
      FL.ViewAudit = this.hasPermissionAtAllLocations(x.Locations, 1);
      FL.SaveAudit = this.hasPermissionAtAllLocations(x.Locations, 2);
      FL.EditAudit = this.hasPermissionAtAllLocations(x.Locations, 3);
      FL.EditLocations = this.hasPermissionAtAllLocations(x.Locations, 4);
      FL.EditUsers = this.hasPermissionAtAllLocations(x.Locations, 5);
      FL.ScheduleAudit = this.hasPermissionAtAllLocations(x.Locations, 6);
      FL.DeleteScheduledAudit = this.hasPermissionAtAllLocations(x.Locations, 10);
      var SUBFL = new FeaturesList();
      SUBFL.ViewAudit = this.hasPermissionAtALocation(x.Locations, 1);
      SUBFL.SaveAudit = this.hasPermissionAtALocation(x.Locations, 2);
      SUBFL.EditAudit = this.hasPermissionAtALocation(x.Locations, 3);
      SUBFL.EditLocations = this.hasPermissionAtALocation(x.Locations, 4);
      SUBFL.EditUsers = this.hasPermissionAtALocation(x.Locations, 5);
      SUBFL.ScheduleAudit = this.hasPermissionAtALocation(x.Locations, 6);
      SUBFL.DeleteScheduledAudit = this.hasPermissionAtALocation(x.Locations, 10);
      AllLocation = new AuditLocation({ LocationID: -1, LocationName: "All", Features: FL, SubLocationFeatures: SUBFL, HasPermission: true })

      this.tableData.push({ User: x, Location: AllLocation })
      x.Locations.forEach(y => {
        this.tableData.push({ User: x, Location: y })
      })
    })
    this.setSubPermissions()
  }
  setSubPermissions() {
    var MinLevel = this.tableData[0].Location.LocationLevelSeq;
    var MaxLevel = this.tableData[0].Location.LocationLevelSeq;

    this.tableData.forEach(x => {
      if (MinLevel > x.Location.LocationLevelSeq || MinLevel == -1) {
        MinLevel = x.Location.LocationLevelSeq;
      }
      if (MaxLevel < x.Location.LocationLevelSeq) {
        MaxLevel = x.Location.LocationLevelSeq;
      }
    })
    if (MaxLevel != MinLevel) {
      for (let i: number = MaxLevel; i > MinLevel; i--) {
        this.tableData.filter(x => x.Location.LocationLevelSeq == i).forEach(y => {
          this.propgateFeatures(y.Location.LocationID, y.User.UserID, y.Location.ParentLocationID);
        })
      }
    }
    this.loadComplete = true;
    this.minLevel = MinLevel;
    this.maxLevel = MaxLevel;
    if (!this.initialLoadComplete) {
      this.filteredData = this.tableData;
      this.initialLoadComplete = true;
    }

  }
  propgateFeatures(locationID: number, userID: number, parentID: number) {
    var returnFeatures: FeaturesList = new FeaturesList();

    var childFeatures = this.tableData.filter(x => x.User.UserID == userID && locationID == x.Location.LocationID)[0].Location.Features;
    var childSubFeatures = this.tableData.filter(x => x.User.UserID == userID && locationID == x.Location.LocationID)[0].Location.SubLocationFeatures;
    var parentSubFeatures;
    if (this.tableData.filter(x => x.User.UserID == userID && parentID == x.Location.LocationID)[0] != undefined) {
      parentSubFeatures = this.tableData.filter(x => x.User.UserID == userID && parentID == x.Location.LocationID)[0].Location.SubLocationFeatures;
    } else {
      parentSubFeatures = new FeaturesList();
    }

    returnFeatures.EditAudit = (childFeatures.EditAudit || childSubFeatures.EditAudit || parentSubFeatures.EditAudit);
    returnFeatures.EditLocations = (childFeatures.EditLocations || childSubFeatures.EditLocations || parentSubFeatures.EditLocations);
    returnFeatures.EditUsers = (childFeatures.EditUsers || childSubFeatures.EditUsers || parentSubFeatures.EditUsers);
    returnFeatures.ViewAudit = (childFeatures.ViewAudit || childSubFeatures.ViewAudit || parentSubFeatures.ViewAudit);
    returnFeatures.SaveAudit = (childFeatures.SaveAudit || childSubFeatures.SaveAudit || parentSubFeatures.SaveAudit);
    returnFeatures.ScheduleAudit = (childFeatures.ScheduleAudit || childSubFeatures.ScheduleAudit || parentSubFeatures.ScheduleAudit);
    returnFeatures.DeleteScheduledAudit = (childFeatures.DeleteScheduledAudit || childSubFeatures.DeleteScheduledAudit || parentSubFeatures.DeleteScheduledAudit);
    if (this.tableData.filter(x => x.User.UserID == userID && parentID == x.Location.LocationID)[0] != undefined) {
      this.tableData.filter(x => x.User.UserID == userID && parentID == x.Location.LocationID)[0].Location.SubLocationFeatures = returnFeatures;
    }

  }
  allFeatureSettingCheck() {
    this.result.Users.forEach(x => {
      var SUBFL = new FeaturesList();
      SUBFL.ViewAudit = this.hasPermissionAtALocationUpdate(this.tableData.filter(y => y.User.UserID == x.UserID), 1);
      SUBFL.SaveAudit = this.hasPermissionAtALocationUpdate(this.tableData.filter(y => y.User.UserID == x.UserID), 2);
      SUBFL.EditAudit = this.hasPermissionAtALocationUpdate(this.tableData.filter(y => y.User.UserID == x.UserID), 3);
      SUBFL.EditLocations = this.hasPermissionAtALocationUpdate(this.tableData.filter(y => y.User.UserID == x.UserID), 4);
      SUBFL.EditUsers = this.hasPermissionAtALocationUpdate(this.tableData.filter(y => y.User.UserID == x.UserID), 5);
      SUBFL.ScheduleAudit = this.hasPermissionAtALocationUpdate(this.tableData.filter(y => y.User.UserID == x.UserID), 6);
      SUBFL.DeleteScheduledAudit = this.hasPermissionAtALocationUpdate(this.tableData.filter(y => y.User.UserID == x.UserID), 10);


      this.tableData.filter(y => y.User.UserID == x.UserID && y.Location.LocationID == -1)[0].Location.SubLocationFeatures = SUBFL;
      var FL = new FeaturesList();
      FL.ViewAudit = this.hasPermissionAtAllLocationsCheck(this.tableData.filter(y => y.User.UserID == x.UserID), 1);
      FL.SaveAudit = this.hasPermissionAtAllLocationsCheck(this.tableData.filter(y => y.User.UserID == x.UserID), 2);
      FL.EditAudit = this.hasPermissionAtAllLocationsCheck(this.tableData.filter(y => y.User.UserID == x.UserID), 3);
      FL.EditLocations = this.hasPermissionAtAllLocationsCheck(this.tableData.filter(y => y.User.UserID == x.UserID), 4);
      FL.EditUsers = this.hasPermissionAtAllLocationsCheck(this.tableData.filter(y => y.User.UserID == x.UserID), 5);
      FL.ScheduleAudit = this.hasPermissionAtAllLocationsCheck(this.tableData.filter(y => y.User.UserID == x.UserID), 6);
      FL.DeleteScheduledAudit = this.hasPermissionAtAllLocationsCheck(this.tableData.filter(y => y.User.UserID == x.UserID), 10);
      this.tableData.filter(y => y.User.UserID == x.UserID && y.Location.LocationID == -1)[0].Location.Features = FL;
    })
  }

  hasPermissionAtALocationUpdate(locations: any[], FeatureType: number) {
    var hasLocation = false
    locations.forEach(x => {
      switch (FeatureType) {
        case 1:
          if (x.Location.Features.ViewAudit) {
            hasLocation = true;
          }
          break;
        case 2:
          if (x.Location.Features.SaveAudit) {
            hasLocation = true;
          }
          break;
        case 3:
          if (x.Location.Features.EditAudit) {
            hasLocation = true;
          }
          break;
        case 4:
          if (x.Location.Features.EditLocations) {
            hasLocation = true;
          }
          break;
        case 5:
          if (x.Location.Features.EditUsers) {
            hasLocation = true;
          }
          break;
        case 6:
          if (x.Location.Features.ScheduleAudit) {
            hasLocation = true;
          }
          break;
        case 10:
          if (x.Location.Features.DeleteScheduledAudit) {
            hasLocation = true;
          }
          break;
        default:
          hasLocation = false;
      }
    })
    return hasLocation;
  }
  hasPermissionAtAllLocationsCheck(locations: any[], FeatureType: number): boolean {
    var allLocations = true;
    locations.forEach(x => {
      if (x.Location.HasPermission && x.Location.LocationID != 1) {

        switch (FeatureType) {
          case 1:
            if (!x.Location.Features.ViewAudit) {
              allLocations = false;
            }
            break;
          case 2:
            if (!x.Location.Features.SaveAudit) {
              allLocations = false;
            }
            break;
          case 3:
            if (!x.Location.Features.EditAudit) {
              allLocations = false;
            }
            break;
          case 4:
            if (!x.Location.Features.EditLocations) {
              allLocations = false;
            }
            break;
          case 5:
            if (!x.Location.Features.EditUsers) {
              allLocations = false;
            }
            break;
          case 6:
            if (!x.Location.Features.ScheduleAudit) {
              allLocations = false;
            }
            break;
          case 10:
            if (!x.Location.Features.DeleteScheduledAudit) {
              allLocations = false;
            }
            break;
          default:
            allLocations = false;
            break;
        }
      }

    })
    return allLocations;
  }
  hasPermissionAtAllLocations(locations: AuditLocation[], FeatureType: number): boolean {
    var allLocations = true;
    locations.forEach(x => {
      if (x.HasPermission) {

        switch (FeatureType) {
          case 1:
            if (!x.Features.ViewAudit) {
              allLocations = false;
            }
            break;
          case 2:
            if (!x.Features.SaveAudit) {
              allLocations = false;
            }
            break;
          case 3:
            if (!x.Features.EditAudit) {
              allLocations = false;
            }
            break;
          case 4:
            if (!x.Features.EditLocations) {
              allLocations = false;
            }
            break;
          case 5:
            if (!x.Features.EditUsers) {
              allLocations = false;
            }
            break;
          case 6:
            if (!x.Features.ScheduleAudit) {
              allLocations = false;
            }
            break;
          case 10:
            if (!x.Features.DeleteScheduledAudit) {
              allLocations = false;
            }
            break;
          default:
            allLocations = false;
            break;
        }
      }

    })
    return allLocations;
  }
  hasPermissionAtALocation(locations: AuditLocation[], FeatureType: number): boolean {
    var hasLocation = false
    locations.forEach(x => {
      switch (FeatureType) {
        case 1:
          if (x.Features.ViewAudit) {
            hasLocation = true;
          }
          break;
        case 2:
          if (x.Features.SaveAudit) {
            hasLocation = true;
          }
          break;
        case 3:
          if (x.Features.EditAudit) {
            hasLocation = true;
          }
          break;
        case 4:
          if (x.Features.EditLocations) {
            hasLocation = true;
          }
          break;
        case 5:
          if (x.Features.EditUsers) {
            hasLocation = true;
          }
          break;
        case 6:
          if (x.Features.ScheduleAudit) {
            hasLocation = true;
          }
          break;
        case 10:
          if (x.Features.DeleteScheduledAudit) {
            hasLocation = true;
          }
          break;
        default:
          hasLocation = false;
      }
    })
    return hasLocation;
  }
  updateSelection(userID: number, locationLevelID: number, locationID: number, featureType: number, event: any) {
    if (locationID == -1) {
      this.updateAllLocations(userID, featureType, event.target.checked)
    } else {
      if (this.tableData.filter(x => x.User.UserID == userID && locationID == x.Location.LocationID)[0].Location.HasPermission) {
        this.updateFeatureList(this.tableData.filter(x => x.User.UserID == userID && locationID == x.Location.LocationID)[0].Location.Features, featureType, event.target.checked);
      }
      //this.updateFeatureList(this.tableData.filter(x => x.User.UserID == userID && locationID == x.Location.LocationID)[0].Location.SubLocationFeatures, featureType, event.target.checked);
      if (locationLevelID != this.maxLevel) {
        this.updateSubLocations(userID, locationLevelID, locationID, featureType, event);
      }
    }
    this.resetSubLocation();
    this.filterListByUser();
  }
  updateSubLocations(userID: number, locationLevelID: number, parentID: number, featureType: number, event: any) {
    this.tableData.filter(x => x.User.UserID == userID && parentID == x.Location.ParentLocationID).forEach(y => {
      this.updateSelection(userID, locationLevelID, y.Location.LocationID, featureType, event);
    });
  }
  updateAllLocations(userID: number, featureType: number, value: boolean) {
    this.tableData.filter(x => x.User.UserID == userID).forEach(y => {
      if (y.Location.HasPermission) {
        this.updateFeatureList(y.Location.Features, featureType, value);
        this.updateFeatureList(y.Location.SubLocationFeatures, featureType, value);
      }
    })
  }
  resetSubLocation() {
    this.tableData.forEach(x => {
      x.Location.SubLocationFeatures = new FeaturesList();
    })
    this.setSubPermissions();
    this.allFeatureSettingCheck();
  }
  updateFeatureList(currentList: FeaturesList, FeatureType: number, value: boolean) {
    if (this.saveStarted) {
      return;
    }
    switch (FeatureType) {
      case 1:
        currentList.ViewAudit = value
        break;
      case 2:
        currentList.SaveAudit = value;
        break;
      case 3:
        currentList.EditAudit = value;
        break;
      case 4:
        currentList.EditLocations = value;
        break;
      case 5: currentList.EditUsers = value;
        break;
      case 6: currentList.ScheduleAudit = value;
        break;
      case 10: currentList.DeleteScheduledAudit = value;
        break;
    }
    this.unsavedChanges = true;

  }
  startSave() {

    if (this.mostRecentRequest.LocationID == -1) {
      return;
    }
    this.saveStarted = true;

    var returnData: UserManagementResult = new UserManagementResult();
    returnData.UPR = this.mostRecentRequest;
    this.result.Users.forEach(x => {
      var Locations: AuditLocation[] = [];
      this.filteredData.filter(y => y.User.UserID == x.UserID).forEach(z => {
        if (z.Location.LocationID != -1) {
          Locations.push(z.Location);
        }
      })
      if (Locations.length > 0) {
        x.Locations = Locations;
        returnData.Users.push(x);
      }

    });
    returnData.IsGroup = this.isGroup;
    this.UMS.saveUserManagementList(returnData).pipe(
      tap((a) => {
        this.result = a;
        this.toastr.addToastr(new Toastr({ Message: "Permissions saved.", Type: 2 }))
        this.setAllLocations();
        this.resetPage();
      })
    ).subscribe();
  }
  resetPage() {
    this.saveStarted = false;
    this.unsavedChanges = false;
    this.filterUser = "";
    this.filteredData = this.tableData;
    this.initialLoadComplete = false;
  }
  goBack() {
    this.router.navigateByUrl("/settings");
  }



  // location functions
  selectLocation(location: AuditLocation) {

    if (location.HasPermission) {
      this.selectedLocation = location;
      this.isLocationSelected = true;
    }

    //this.loadedLocation = this.selectedLocation;

    //this.resetParentLocation();
  }
  resetLocations() { this.initialList(); this.isLocationSelected = false; this.selectedLocation = new AuditLocation(), this.saveStarted = false; };
  locationHasChildren(location: AuditLocation) {
    return this.originalData.findIndex(x => x.ParentLocationID == location.LocationID) != -1;
  };
  confirmLocation() {
    var UPR: UserPermissionsRequest = new UserPermissionsRequest({ "IsGroup": this.isGroup, "IncludeSublocations": this.includeSubLocations, "LocationID": this.selectedLocation.LocationID })
    this.UMS.userManagementList(UPR).pipe(tap((a) => { this.result = a; this.setAllLocations(); this.showPermissions = true; this.collapseSearch = true, this.mostRecentRequest = UPR, this.initialLoadComplete = false; })).subscribe();
  }
  buildLocationDDL(locationID: number) {
    this.topLevel = false;
    var auditLocations: AuditLocation[] = [];
    this.originalData.forEach(e => {
      if (e.ParentLocationID == locationID) {
        auditLocations.push(e);
      }
    });
    this.possibleLocations = auditLocations;
  }
  showSearch() {
    this.collapseSearch = false;
  }
  filterListByUser() {
    this.filteredData = this.tableData;
    if (this.filterUser.length == 0) {
      return;
    } else {
      this.filteredData = this.tableData.filter(e => e.User.DisplayName.toLowerCase().includes(this.filterUser.toLowerCase()))
    }
  }
}
