<div class=" border-2 border-solid transition-all ease-in-out delay-150 border-green-300 my-1 w-full   px-4 py-2 mb-4 rounded-md shadow-[0px_0px_7px_-2px_rgba(0,0,0,0.5)]"
    [ngClass]="{'border-grey-900':action.Resolved, 'border-red-600 ': hasDeadlinePassed(action.ActionDeadline) && !action.Resolved, 'border-warning-amber':isDeadlineDue(action.ActionDeadline)  && !action.Resolved}">
    <div class="flex flex-col lg:flex-row justify-between mb-2">
        <div>
            <h4 *ngIf="action.Resolved">Completed: {{getDeadlineText(action.ResolvedDate)}}</h4>
            <h4 *ngIf="!action.Resolved">Due: {{getDeadlineText(action.ActionDeadline)}}</h4>
            <p *ngIf="action.Resolved" class="text-sm font-semibold">Due: {{getDeadlineText(action.ActionDeadline)}}</p>
            <p class="text-sm font-semibold">Reported: {{getDeadlineText(action.DateRecorded)}}</p>
        </div>
        <div class="flex flex-col items-end">
            <h4 *ngIf="action.Resolved">Closed By: {{action.ResolvedUser}}</h4>
            <h4 *ngIf="!action.Resolved">{{action.ActionAssignee}}</h4>
            <p class="text-sm font-semibold">Location: {{action.AuditLocation.LocationName}}</p>
        </div>
    </div>
    <div>
        <div class="border-t-[1px] border-t-grey-300 w-full"></div>
        <div class="my-2">
            <h5 class=""> {{action.QuestionHeader}}</h5>
            <p *ngIf="action.QuestionBody.length>0" class="">{{action.QuestionBody}}</p>
            <h5 *ngIf="action.Reference.length>0">{{action.Reference}}</h5>
            <h5 class="font-bold">{{action.ActionDescription}}</h5>
        </div>
        <div *ngIf="action.ActionResponseList.length>0 && action.ActionResponseList[0].Note.length>0" class="flex">
            <p><strong class="mr-1">{{getDeadlineText(action.ActionResponseList[0].ResponseDate)}}</strong></p>
            <p>{{action.ActionResponseList[0].Note}}</p>
        </div>
        <div *ngIf="action.ActionResponseList.length>1">
            <p class="text-sm">+ {{action.ActionResponseList.length - 1}} Notes...</p>
        </div>
        <div *ngIf="!updateState && action.Resolved == false" class="flex justify-end mt-2">
            <button (click)="changeUpdateState()" [disabled]="disabledButton" class="btn-primary">Update</button>
        </div>
        <div *ngIf="action.Resolved" class="mt-3 flex lg:justify-start justify-center">
            <p class=" mr-2 hover:cursor-pointer hover:text-green-700 hover:underline" (click)="detailAction(action)">
                Full Details</p>
        </div>
        <div *ngIf="updateState && action.Resolved == false" class="mt-4">
            <div class="border-t-[1px] border-t-green-700 w-full"></div>
            <h4 class="mt-4">New Action Details</h4>
            <div>
                <textarea rows="3" placeholder="Notes" [(ngModel)]="ar.Note"></textarea>
                <div *ngIf="noteWarning" class="mt-2">
                    <p class="text-red-600">A note must be added to close an action.</p>
                </div>
                <div class="flex justify-end my-3 items-center">
                    <h5>Closed</h5><app-toggle-switch [(checked)]="ar.Resolved" class="ml-2"></app-toggle-switch>
                </div>
                <p *ngIf="ar.Resolved" class="text-center">This action will be marked as closed on update.</p>
            </div>

            <div class="flex mt-2 lg:flex-row lg:justify-end flex-col-reverse">
                <button class="lg:mr-2 lg:mt-0 mt-2  btn-warning" (click)="clearChanges()">Undo</button>
                <button class="btn-primary" (click)="updateAction(action)">Confirm Update</button>
            </div>
            <div class="mt-3 flex lg:justify-start justify-center hover:cursor-pointer">
                <p class=" mr-2  text-green-700 underline text-lg"
                    (click)="detailAction(action)">Full Details</p>
            </div>
        </div>
    </div>
</div>