import { Component, Input } from '@angular/core';
import { TaskInfo } from 'src/app/core-module/models/Tasks/TaskInfo';
import { UtilService } from 'src/app/core-module/services/util.service';
import { format } from 'date-fns';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core-module/services/local-storage.service';
import { AuditService } from 'src/app/core-module/services/audit.service';
import { UserLocationSave } from 'src/app/core-module/models/user-location-save';
import { FlattenedQuestionHolder } from 'src/app/core-module/models/Audits/flattened-question-holder';
import { Question } from 'src/app/core-module/models/Audits/question';
import { tap } from 'rxjs';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrl: './task-detail.component.scss'
})
export class TaskDetailComponent {
  @Input() task: TaskInfo;
  @Input() LocalAudits:UserLocationSave[]=[]

  public showAuditStartOptions:boolean = false;

  public disableStart:boolean = false;

  constructor(private util:UtilService, private router:Router, private auditService:AuditService){

  }


  frequencyString():string{
    if(this.task.FrequencyMultiplier>1){
      return this.task.FrequencyMultiplier + " times " + this.task.Frequency.Description
    }else{
      return this.task.Frequency.Description;
    }
  }
  humaniseDate(entryDate: Date): string {
    return this.util.humanDate(entryDate);
  }
  periodEndString():string{
    switch (this.task.Frequency.ID) {
          //daily
          case 2:
            return this.humaniseDate(this.task.PeriodEnd);
            break;
          //weekly
          case 3:
            return format(this.task.PeriodEnd, 'do MMM yy');
            break;
          //monthly //quarterly //semi annual //annual
          case 5:case 6: case 7:case 8:
            return format(this.task.PeriodEnd, 'MMM yy');
            break;
        }
        return '';
  }
  lastCompleted():string{
    var testDate = new Date();
    testDate.setFullYear(2000);
    if(new Date(this.task.LastCompleted)<testDate){
      return '-';
    }else{
      return this.humaniseDate(this.task.LastCompleted);
    }
  }
  startAudit(){    

    this.router.navigateByUrl('/selectlocation', { state: { "audit": this.task.AuditID,"location":this.task.Location.LocationID } });
  }
  checkExistingAudits(){
    this.showAuditStartOptions = true;
  }
  collapseAuditStartOptions(){
    this.showAuditStartOptions = false;
  }
  getReferenceQuestion(fq:FlattenedQuestionHolder):string{
    var returnValue = '';
    fq.Questions.forEach(e=>{
      if(e.IsReference && returnValue.length == 0){
        returnValue = this.getQuestionAnswerString(e);
      }
    })
    return returnValue;

  }
    getQuestionAnswerString(q:Question):string{
      var returnValue = "N/A";
      if(q.QuestionType.ID == 4){
        returnValue == q.CompletionInfo.Answer
      }
      if(q.QuestionType.ID == 1 || q.QuestionType.ID == 2){
  
        q.QuestionAnswers.forEach(e=>{
          if(e.QuestionAnswerID == q.CompletionInfo.Answer){
            returnValue = e.AnswerText;
          };
        });
      }
      return returnValue;
    }
    continueAudit(ULS:UserLocationSave){
      this.router.navigateByUrl('audit', { state: { "SavedAudit": ULS.FlattenedQuestions[0], "SavedLocation": ULS.Location,"AuditGUID":ULS.AuditGUID } });
    }
    startNewAudit(){
      this.disableStart = true;
      this.auditService.getAudit(this.task.AuditID,this.task.Location.LocationID).pipe(tap(res=>{
        if(res.IsError){
          this.disableStart = false;
          //alert of issue
        }else{
          this.router.navigateByUrl('audit', { state: { "SavedAudit": res.QuestionHolder, "SavedLocation": this.task.Location} })
        }
      })).subscribe()
    }
}

