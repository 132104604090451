<!--<div class="container">
  <h1 class="lg:p-8 lg:pb-4 p-2">Start Audit</h1>
  <div  class="lg:ml-8 p-2">
    <div class="px-4">
      <label class="block pt-4">
        <h3 class="">Audit</h3>
        <select class="text-box w-full md:max-w-xl mt-2 standardTextSize" [(ngModel)]="selectedAudit">
          <option *ngFor="let audit of a.Audits" [ngValue]="audit">
            {{ audit.AuditName }}
          </option>
        </select>
      </label>
    </div>
    <div class="p-4" *ngIf="
        selectedAudit != null &&
        selectedAudit.AuditInfo.ReportDescription.length != 0
      ">
      <p class="standardTextSize">
        {{ selectedAudit.AuditInfo.ReportDescription }}
      </p>
    </div>
    <div class="p-4">
      <button class="btn-primary-full w-full md:max-w-xl" (click)="startAudit()" [class.disabled]="!canStart"
        [disabled]="!canStart">
        <span class="standardTextSize" *ngIf="canStart">Next ></span>
        <span class="standardTextSize" *ngIf="!canStart">Selecting...</span>
      </button>
    </div>
  </div>
  
</div>-->



<div class="container">
  <h1 class="lg:p-8 lg:pb-4 p-2">Start Audit</h1>
  <div class="lg:ml-8 p-2">
    <div class="px-4">
      <label class="block pt-4">
        <h3 class="">Audit Type</h3>
        <select class="text-box w-full md:max-w-xl mt-2 standardTextSize" [(ngModel)]="selectedAuditType" (change)="filterAudits()">
          <option *ngFor="let at of AuditTypes" [ngValue]="at">
            {{ at.Description }}
          </option>
        </select>
      </label>
      <label class="block pt-4">
        <h3 class="">Audit</h3>
        <select class="text-box w-full md:max-w-xl mt-2 standardTextSize" [(ngModel)]="selectedAudit">
          <option *ngFor="let audit of Audits" [ngValue]="audit">
            {{ audit.AuditName }}
          </option>
        </select>
      </label>
    </div>
    <div class="p-4 mt-2">
      <button class="btn-primary-full w-full md:max-w-xl" (click)="startAudit()" [class.disabled]="!canStart"
        [disabled]="!canStart">
        <span class="standardTextSize" *ngIf="canStart">Next ></span>
        <span class="standardTextSize" *ngIf="!canStart">Selecting...</span>
      </button>
    </div>
    <div class=" mx-3 " *ngIf="selectedAudit != null">

      <div class="border-grey-500 border-t-[1px] pb-2">
        <h5 class="mt-2 font-semibold">Audit Details</h5>
        <p class="mb-2 mt-2">Audit Type: <strong>{{selectedAudit.AuditInfo.AuditType.Description}}</strong></p>
        <p class="mb-2">Audit Frequency: <strong>{{selectedAudit.AuditInfo.Frequency.Description}}</strong></p>
        <p>Last Updated: <strong *ngIf="selectedAudit.AuditInfo.LastUpdated == '0001-01-01T00:00:00'">Initial
            Version</strong><strong
            *ngIf="selectedAudit.AuditInfo.LastUpdated != '0001-01-01T00:00:00'">{{getHumanDate(this.selectedAudit.AuditInfo.LastUpdated)}}</strong>
        </p>
        <div class="mt-2" *ngIf="selectedAudit.AuditInfo.ReportDescription.length != 0">
          <p class="standardTextSize">Report Description: <strong>{{ selectedAudit.AuditInfo.ReportDescription
              }}</strong>

          </p>
        </div>
      </div>
  
      <div class="border-grey-300 border-t-2" *ngIf="selectedAudit.HistoricInfo.length>0">
        <h5 class="mt-4 font-semibold">Last Completed</h5>
        <div  class=" mt-2 ">
          <table class="">
            <thead>
              <!--<tr><th colspan="2">Audits Last Completed</th></tr>
              <tr>
                <th class="text-left">Location</th>
                <th class="text-left pl-2">Date</th>
              </tr>-->
            </thead>
            <tbody>
              <tr *ngFor="let a of selectedAudit.HistoricInfo">                
                <td class="text-left">{{a.LocationName}}</td>
                <td class="text-right pl-2">{{getHumanDate(a.LastCompleted)}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>


  </div>

</div>