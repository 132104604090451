import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { AuditCollectionResult } from 'src/app/core-module/models/Audits/audit-collection-result';
import { FlattenedQuestionHolder } from 'src/app/core-module/models/Audits/flattened-question-holder';
import { DDL } from 'src/app/core-module/models/ddl';
import { AuditService } from 'src/app/core-module/services/audit.service';
import { UtilService } from 'src/app/core-module/services/util.service';

@Component({
  selector: 'app-start-audit',
  templateUrl: './start-audit.component.html',
  styleUrls: ['./start-audit.component.scss']
})
export class StartAuditComponent implements OnInit {

  canStart: boolean = false;
  a = new AuditCollectionResult();
  public AuditTypes:DDL[]=[];
  public Audits:FlattenedQuestionHolder[]=[];
  public selectedAuditType:DDL = new DDL();
  selectedAudit: any;

  constructor(private audit: AuditService, private router: Router,private util:UtilService) { }

  ngOnInit() {
    this.audit.getAudits().pipe(
      tap(res => {
        this.a = res;
        this.selectedAudit = this.a.Audits[0];
        this.orderAudits();
        this.createAuditTypes();
        this.Audits = this.a.Audits;
        this.canStart = true;
      })
    ).subscribe();
  }

  startAudit() {
    this.canStart = false;
    this.router.navigateByUrl('/selectlocation', { state: { "audit": this.selectedAudit } });
  }
  createAuditTypes(){
    var tempAuditTypeList:DDL[]=[];
    this.a.Audits.forEach(e=>{
      if(tempAuditTypeList.findIndex(f=>f.ID == e.AuditInfo.AuditType.ID)==-1){
          tempAuditTypeList.push(e.AuditInfo.AuditType);
      }
    })
    tempAuditTypeList.sort((a,b)=>a.Description.localeCompare(b.Description));
    var allType = new DDL({"ID":-1,"Description":"All"})
    tempAuditTypeList.unshift(allType);
    this.AuditTypes = tempAuditTypeList;
    this.selectedAuditType = allType;
  }
  filterAudits(){
    if(this.selectedAuditType.ID==-1){
      this.Audits = this.a.Audits;
    }else{
      this.Audits = this.a.Audits.filter(e=>e.AuditInfo.AuditType.ID == this.selectedAuditType.ID);
    }
    if(this.Audits.findIndex(e=>e.AuditID==this.selectedAudit.AuditID)==-1){
      this.selectedAudit = this.Audits[0];
    }    
  }
  orderAudits() {
    this.a.Audits = this.a.Audits.sort((a, b) => {
      if (a.AuditName > b.AuditName) {
        return 1;
      }

      if (a.AuditName < b.AuditName) {
        return -1;
      }

      return 0;
    })
  }
  getHumanDate(dateEntry:Date):string{
    return this.util.humanDate(dateEntry);
  }


}
