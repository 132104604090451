export class BasicAction{
    public ActionID:number = -1;
    public ActionAuthor:string = "";
    public ActionDescription:string = "";
    public AssignedTo:string = "";
    public Resolved:boolean;
    public ResolvedDate:Date;
    public ResolvedBy:string = "";
    public ActionDeadline:Date;
    constructor(values: Object = {}){
        Object.assign(this, values);     
    }
}