import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuditCollectionResult } from '../models/Audits/audit-collection-result';
import { AuditResult } from '../models/Audits/audit-result';
import { AuditRequest } from '../models/Audits/audit-request';
import { LocalStorageService } from './local-storage.service';
import { FlattenedQuestionHolder } from '../models/Audits/flattened-question-holder';
import { BasicResult } from '../models/basic-result';
import { AuditHolder } from '../models/Audits/audit-holder';
import { AuditTypeResult } from '../models/AuditTypes/audit-type-result';
import { AuditLocation } from '../models/Locations/audit-location';
import { UserLocationSave } from '../models/user-location-save';
import { QuestionAttachment } from '../models/Audits/question-attachment';
import { ActionResult } from '../models/Compliance-Actions/action-result';
import { DDLResult } from '../models/ddl-result';
import { BasicAuditResult } from '../models/Audits/AuditResults/basic-audit-result';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private httpClient: HttpClient, private localStorageService: LocalStorageService) { }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    observe: "response" as "response",
    responseType: "json" as const,
    withCredentials: true
  };

  httpUploadOptions = {
    withCredentials: true,
    reportProgress: true,
    observe: 'events' as 'events'
  };

  /*getAudits():Observable<Audit[]>{
    let a = [
      {AuditID: 1, AuditName: "Audit 1", AuditDescription: "This is audit 1"},
      {AuditID: 2, AuditName: "Audit 2", AuditDescription: "This is audit 2"},
      {AuditID: 3, AuditName: "Audit 3", AuditDescription: "This is audit 3"},
    ]
    return of(a);
  }*/
  getAudits(ShowInternal: boolean = true): Observable<AuditCollectionResult> {
    return this.httpClient.post<AuditCollectionResult>(`${environment.apiUrl}audits/auditlist`,ShowInternal, this.httpOptions).pipe(
      map(res => {
        return new AuditCollectionResult(res.body!);
      })
    );
  }
  getAudit(AuditID: number,LocationID: number): Observable<AuditResult> {
    return this.httpClient.post<AuditResult>(`${environment.apiUrl}audits/getaudit`, new AuditRequest(AuditID,true,LocationID), this.httpOptions).pipe(
      map(res => {
        return new AuditResult(res.body!);
      })
    );
  }
  getLocalAudits() {
    this.localStorageService.loadData(1);
    var username = '';
    this.localStorageService.observables.localRef.find(e=>{return e.ID == "userSettings"})?.Obs.pipe(tap((f)=>{username=f.Username})).subscribe();
    var returnAudits:UserLocationSave[] = [];
    this.localStorageService.loadData(2);
    this.localStorageService.observables.localRef.find(e=>{return e.ID == "careAudits"})?.Obs.pipe(tap((f)=>{if(f != null){returnAudits = f} })).subscribe();

    returnAudits = returnAudits.filter(e=>e.Username == username);
    return of(returnAudits);
    
  }
  setLocalAuditGUIDs(){
    var fullAuditList:UserLocationSave[]=[];
    this.getALLLocalAudits().pipe(tap(a=>{
      a.forEach(e=>{
        fullAuditList.push(new UserLocationSave(e))
      });
    })).subscribe();
    fullAuditList.forEach(e=>{
      if(e.AuditGUID == null||e.AuditGUID.length == 0){
        e.AuditGUID = crypto.randomUUID();
      }
    })
    this.localStorageService.setData(2,fullAuditList);
  }
  getALLLocalAudits() {
    var returnAudits:UserLocationSave[] = [];
    this.localStorageService.loadData(2);
    this.localStorageService.observables.localRef.find(e=>{return e.ID == "careAudits"})!.Obs.pipe(tap((f)=>{returnAudits = f})).subscribe();
    return of(returnAudits);
    
  }
  getLocalAudit(auditID: number, locationID: number, username: string): UserLocationSave {
    var ULS = new UserLocationSave();
    this.getALLLocalAudits().pipe(tap(
      (f)=>{
        f.forEach((e: UserLocationSave) => {
          var temp = new UserLocationSave(e);
          if (temp.FlattenedQuestions[0].AuditID == auditID && temp.Location.LocationID == locationID && temp.Username == username) {
            ULS = temp;
          }
        })
      }

    )).subscribe();


    /*this.getLocalAudits().pipe().forEach(f => {
      f.forEach((e: UserLocationSave) => {
        var temp = new UserLocationSave(e);
        if (temp.FlattenedQuestions[0].AuditID == auditID && temp.Location.LocationID == locationID && temp.Username == username) {
          ULS = temp;
        }
      });
    });*/
    return ULS;
  }
  removeLocalAudit(auditGUID:string, username: string) {
    var Audits: UserLocationSave[] = [];
    /*this.getLocalAudits().pipe().forEach(f => {    
      console.log(f); 
      f.forEach((e: UserLocationSave) => {
        var temp = new UserLocationSave(e);
        if (temp.FlattenedQuestions[0].AuditID != auditID || temp.Location.LocationID != locationID || temp.Username != username) {
          Audits.push(temp);
        }
      });
    });*/

    this.getALLLocalAudits().pipe(
      map(f => {
        let audits = f
          .filter((e: UserLocationSave) => e.AuditGUID !== auditGUID)
          .map((e:any) => new UserLocationSave(e));
    
        return audits;
      }),
      switchMap(audits =>{
        this.localStorageService.updateData(2, audits);
        return of(1);
      } )
      ).subscribe();

    //
  }
  saveAudit(audit: AuditHolder): Observable<ActionResult> {
    audit = this.flattenActions(audit);
    return this.httpClient.post<ActionResult>(`${environment.apiUrl}audits/submitaudit`, audit, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }

  submitAudit(audit: AuditHolder): string {
    return "";
  }

  getAuditTypes(): Observable<AuditTypeResult> {
    return this.httpClient.post<AuditTypeResult>(`${environment.apiUrl}lookup/audittypes`, { "CustomerID": -1, "IncludeInactive": true }, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }

  getQuestionTypes(): Observable<AuditTypeResult> {
    return this.httpClient.post<AuditTypeResult>(`${environment.apiUrl}lookup/questiontypes`, false, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }
  removeFile(fileName:string): Observable<BasicResult> {
    let req = JSON.stringify(fileName);
    return this.httpClient.post<BasicResult>(`${environment.apiUrl}audits/removeattachment`,req, this.httpOptions).pipe(
      map(res => {
        return new BasicResult(res);
      })
    );
  }
  private flattenActions(audit:AuditHolder):AuditHolder{
    audit.Audit.Questions.forEach(e=>{
      e.CompletionInfo.Actions.forEach(f=>{
        if(f.StandardAction.StandardQuestionEntryID!=-1){
          f.ActionNote = f.StandardAction.StandardEntryText;
        }
      })
    })
    return audit;
  }

  uploadFile(attachment:QuestionAttachment, data:File){
    
    return this.httpClient.post<string>(`${environment.apiUrl}audits/saveattachment`,data,this.httpUploadOptions);
  }

  saveAuditChanges(audit:FlattenedQuestionHolder):Observable<BasicResult>{
    let req = {Audit: audit};
    return this.httpClient.post<BasicResult>(`${environment.apiUrl}audits/savechanges`,req,this.httpOptions).pipe(
      map(res => {
        return new BasicResult(res.body!);
      })
    );
  }
  deleteAudit(auditID:number):Observable<BasicResult>{
    return this.httpClient.post<BasicResult>(`${environment.apiUrl}audits/deleteaudit`,auditID,this.httpOptions).pipe(
      map(res => {
        return new BasicResult(res.body!);
      })
    );
  }
  getAuditLocationsAuditList():Observable<BasicAuditResult>{
    return this.httpClient.get<BasicAuditResult>(`${environment.apiUrl}audits/getauditlocationauditlist`,this.httpOptions).pipe(
      map(res => {
        return new BasicAuditResult(res.body!);
      })
    );
  }
  getAuditUsersAuditList():Observable<BasicAuditResult>{
    return this.httpClient.get<BasicAuditResult>(`${environment.apiUrl}audits/getaudituserauditlist`,this.httpOptions).pipe(
      map(res => {
        return new BasicAuditResult(res.body!);
      })
    );
  }

}
