<div class="container p-4">
    <div class="px-4 mt-2">
        <button class="btn-inverse py-1" (click)="goBack()">
            < Back</button>
    </div>

    <div class="border-solid border-2 border-grey-500 mb-1 mt-3 mx-4"></div>


    <h1 class="p-4" *ngIf="!isGroup">User Permissions</h1>
    <h1 class="p-4" *ngIf="isGroup">Group Permissions</h1>

    <div class="p-4">
        <div class="p-3 border-grey-500 border-2 rounded-md">
            <h3 *ngIf="collapseSearch && loadComplete">{{selectedLocation.LocationName}} <span *ngIf="includeSubLocations" class="font-bold text-xl md:text-2xl">(w/Sub
                    Locations)</span><span class="text-center material-symbols-outlined font-bold text-xl md:text-2xl self-center hover:cursor-pointer mr-1 align-sub " (click)="showSearch()">edit</span> </h3>
            <div *ngIf="!collapseSearch">

<div class="justify-between flex ">
    <h3 class="mb-5 mt-3">Select Location</h3>
    <button class="btn-inverse self-center" (click)="resetLocations()">Reset Locations</button>
</div>
                

                <div *ngIf="locationsReady">
                    <div *ngFor="let l of possibleLocations" class="flex w-full my-2">
                        <span
                            class="p-2 md:p-4 flex-grow standardTextSize border-spacing-1.5 border-b-grey-500 border-s-2 flex  align-middle hover:hover-for-select"
                            [ngClass]="{'selected': l.LocationID == selectedLocation.LocationID, 'hover:hover-for-select  ':l.HasPermission, 'bg-grey-100 hover:cursor-not-allowed':!l.HasPermission,' bg-grey-300':l.HasPermission&&l.LocationID!=selectedLocation.LocationID}"
                            (click)="selectLocation(l)">{{l.LocationName}}<span *ngIf="!l.Active"
                                class="ml-2 text-red-600 font-bold">(Inactive)</span></span>
                        <div *ngIf="locationHasChildren(l)" class=" bg-standardwhite "
                            (click)="buildLocationDDL(l.LocationID)">
                            <span
                                class="standardTextSize text-offblack hover:hover-for-select bg-grey-300 border-grey-900 border-spacing-2 border-s h-full px-4 flex items-center">Sub
                                Locations</span>
                        </div>
                    </div>
                    <div class="flex mt-5 mr-2 items-center">
                        <p class="">Include Sub Locations:</p>
                        <app-toggle-switch [(checked)]="includeSubLocations" class="ml-2"></app-toggle-switch>
                    </div>
                    <div class="mt-6" *ngIf="true">
                        <button class="btn-primary-full max-w-xl" (click)="confirmLocation()"
                            [class.disabled]="!canStart" [disabled]="!isLocationSelected">
                            <span class="standardTextSize" *ngIf="isLocationSelected">Next ></span>
                            <span class="standardTextSize" *ngIf="!isLocationSelected">Choose Location</span>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div *ngIf="showPermissions" class="mt-6 px-6">
        <h3 class="text-red-600" *ngIf="false">You currently have unsaved changes, remember to save!</h3>
        <div class="flex flex-col md:flex-row align-middle mb-4">
            <p class="mr-2 mb-2 md:mb-0 md:self-center">Search User</p>
            <input placeholder="Name" class="text-box w-full md:w-2/5" type="text" (keyup)="filterListByUser()" [(ngModel)]="filterUser"/>
        </div>
        
        <table>
            <thead>
                <tr>
                    <th class="pr-2">User</th>
                    <th class="pr-2">Location</th>
                    <th class="pr-2">View Audits</th>
                    <th class="pr-2">Save Audits</th>
                    <th class="pr-2">Edit Audits</th>
                    <th class="pr-2">Edit Locations</th>
                    <th class="pr-2">Edit Users</th>
                    <th class="pr-2">Schedule Audits</th>
                    <th class="pr-2">Delete Scheduled Audits</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let a of filteredData"
                    [ngClass]="{'border-solid border-t-2 py-4 border-t-offblack':a.Location.LocationLevelID == -1}">

                    <td class="pr-4" [ngClass]="{'font-bold':a.User.UserTypeID == 2}"><span
                            *ngIf="a.Location.LocationLevelID == -1">{{a.User.DisplayName}}</span></td>
                    <td class="pr-4"
                        [ngClass]="{'text-grey-900':!a.Location.HasPermission || !a.Location.Active,'pl-2':(a.Location.LocationLevelSeq) == 1,'pl-4':(a.Location.LocationLevelSeq) == 2,'pl-6':(a.Location.LocationLevelSeq) == 3,'pl-8':(a.Location.LocationLevelSeq) >= 4,'border-solid border-b-[1px] border-b-green-300':a.Location.LocationLevelID == -1}">
                        {{a.Location.LocationName}} <span *ngIf="!a.Location.Active" class="text-red"> (Inactive)</span>
                    </td>
                    <td class="pr-4  content-center"
                        [ngClass]="{'border-solid border-b-[1px] border-b-green-300':a.Location.LocationLevelID == -1}">
                        <div class="flex justify-center ">
                            <input type="checkbox" [disabled]="!a.Location.HasPermission || saveStarted"
                                *ngIf="a.Location.HasPermission"
                                [ngClass]="{'bg-grey-900':a.Location.SubLocationFeatures.ViewAudit && !a.Location.Features.ViewAudit,' cursor-not-allowed':!a.Location.HasPermission}"
                                [checked]="a.Location.Features.ViewAudit"
                                (change)="updateSelection(a.User.UserID,a.Location.LocationLevelID,a.Location.LocationID,1,$event)" />
                        </div>
                    </td>
                    <td class="pr-4"
                        [ngClass]="{'border-solid border-b-[1px] border-b-green-300':a.Location.LocationLevelID == -1}">
                        <div class="flex justify-center ">
                            <input type="checkbox" [disabled]="!a.Location.HasPermission || saveStarted"
                                *ngIf="a.Location.HasPermission"
                                [ngClass]="{'bg-grey-900':a.Location.SubLocationFeatures.SaveAudit && !a.Location.Features.SaveAudit,'cursor-not-allowed':!a.Location.HasPermission}"
                                [checked]="a.Location.Features.SaveAudit"
                                (change)="updateSelection(a.User.UserID,a.Location.LocationLevelID,a.Location.LocationID,2,$event)" />
                        </div>

                    </td>
                    <td class="pr-4"
                        [ngClass]="{'border-solid border-b-[1px] border-b-green-300':a.Location.LocationLevelID == -1}">
                        <div class="flex justify-center ">
                            <input type="checkbox" [disabled]="!a.Location.HasPermission || saveStarted"
                                *ngIf="a.Location.HasPermission"
                                [ngClass]="{'bg-grey-900':a.Location.SubLocationFeatures.EditAudit && !a.Location.Features.EditAudit,'cursor-not-allowed':!a.Location.HasPermission}"
                                [checked]="a.Location.Features.EditAudit"
                                (change)="updateSelection(a.User.UserID,a.Location.LocationLevelID,a.Location.LocationID,3,$event)" />
                        </div>

                    </td>
                    <td class="pr-4"
                        [ngClass]="{'border-solid border-b-[1px] border-b-green-300':a.Location.LocationLevelID == -1}">
                        <div class="flex justify-center ">
                            <input type="checkbox" [disabled]="!a.Location.HasPermission || saveStarted"
                                *ngIf="a.Location.HasPermission"
                                [ngClass]="{'bg-grey-900':a.Location.SubLocationFeatures.EditLocations && !a.Location.Features.EditLocations,' cursor-not-allowed':!a.Location.HasPermission}"
                                [checked]="a.Location.Features.EditLocations"
                                (change)="updateSelection(a.User.UserID,a.Location.LocationLevelID,a.Location.LocationID,4,$event)" />
                        </div>

                    </td>
                    <td class="pr-4"
                        [ngClass]="{'border-solid border-b-[1px] border-b-green-300':a.Location.LocationLevelID == -1}">
                        <div class="flex justify-center ">
                            <input type="checkbox" [disabled]="!a.Location.HasPermission || saveStarted"
                                *ngIf="a.Location.HasPermission"
                                [ngClass]="{'bg-grey-900':a.Location.SubLocationFeatures.EditUsers && !a.Location.Features.EditUsers,' cursor-not-allowed':!a.Location.HasPermission}"
                                [checked]="a.Location.Features.EditUsers"
                                (change)="updateSelection(a.User.UserID,a.Location.LocationLevelID,a.Location.LocationID,5,$event)" />
                        </div>

                    </td>
                    <td [ngClass]="{'border-solid border-b-[1px] border-b-green-300':a.Location.LocationLevelID == -1}">
                        <div class="flex justify-center ">
                            <input type="checkbox" [disabled]="!a.Location.HasPermission || saveStarted"
                                *ngIf="a.Location.HasPermission"
                                [ngClass]="{'bg-grey-900':a.Location.SubLocationFeatures.ScheduleAudit && !a.Location.Features.ScheduleAudit,' cursor-not-allowed':!a.Location.HasPermission}"
                                [checked]="a.Location.Features.ScheduleAudit"
                                (change)="updateSelection(a.User.UserID,a.Location.LocationLevelID,a.Location.LocationID,6,$event)" />
                        </div>

                    </td>
                    <td [ngClass]="{'border-solid border-b-[1px] border-b-green-300':a.Location.LocationLevelID == -1}">
                        <div class="flex justify-center ">
                            <input type="checkbox" [disabled]="!a.Location.HasPermission || saveStarted"
                                *ngIf="a.Location.HasPermission"
                                [ngClass]="{'bg-grey-900':a.Location.SubLocationFeatures.DeleteScheduledAudit && !a.Location.Features.DeleteScheduledAudit,' cursor-not-allowed':!a.Location.HasPermission}"
                                [checked]="a.Location.Features.DeleteScheduledAudit"
                                (change)="updateSelection(a.User.UserID,a.Location.LocationLevelID,a.Location.LocationID,10,$event)" />
                        </div>

                    </td>
                </tr>
            </tbody>
        </table>
        <div>
            <button class="btn-primary" (click)="startSave()" loading="" [disabled]="saveStarted||!loadComplete||this.filteredData.length==0">
                <span *ngIf="!saveStarted&&loadComplete">Save</span>
                <span *ngIf="saveStarted">Saving...</span>
                <span *ngIf="!loadComplete">Loading...</span>
            </button>
        </div>

    </div>

</div>