import { BasicAction } from "../../Audits/AuditResults/basic-action";
import { RowInformation } from "./row-information";

export class NonComplianceRow {
    public Info: RowInformation = new RowInformation();
    public Compliant:boolean;
    public Reference:string="";
    public ReferenceQuestion:string="";
    public Actions:BasicAction[]=[];
    public CompletedQuestionsCounter:number = 0;
    public NonCompliantCounter:number = 0;
   
    //fe only
    public CompliancePerc:number = 0;
    public SubNonComplianceRows:NonComplianceRow[]=[];

    constructor(values: Object = {}) {
        Object.assign(this, values);
        if (values.hasOwnProperty('Info')) {
            this.Info = new RowInformation((values as NonComplianceRow)['Info'] as RowInformation);
        }
        if (values.hasOwnProperty('Actions')) {
            this.Actions = [];
            ((values as NonComplianceRow)['Actions'] as BasicAction[]).forEach(element => {
                this.Actions.push(new BasicAction(element));
            });
        }
    }
}