export class RowInformation{
    public AuditResultDetailID:number = -1;
    public FrequencyID:number = -1;
    public FrequencyDescription:string = "";
    public FrequencyMultiplier:number = 1;
    public AuditID:number = -1;
    public AuditName:string = "";
    public AuditQuestionID:number = -1;
    public QuestionHeader:string = "";
    public AuditTypeID:number = -1;
    public TypeDescription:string = "";
    public LocationID:number = -1;
    public LocationName:string = "";  
    public DateRecorded:Date; 
    constructor(values: Object = {}){
        Object.assign(this, values);}    
}