import { Component } from '@angular/core';
import { tap } from 'rxjs';
import { BasicAuditResult } from 'src/app/core-module/models/Audits/AuditResults/basic-audit-result';
import { BasicAuditReturn } from 'src/app/core-module/models/Audits/AuditResults/basic-audit-return';
import { DDL } from 'src/app/core-module/models/ddl';
import { AuditLocation } from 'src/app/core-module/models/Locations/audit-location';
import { TaskInfo } from 'src/app/core-module/models/Tasks/TaskInfo';
import { TasksResult } from 'src/app/core-module/models/Tasks/TasksResult';
import { UserLocationSave } from 'src/app/core-module/models/user-location-save';
import { AuditService } from 'src/app/core-module/services/audit.service';
import { TasksService } from 'src/app/core-module/services/tasks.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrl: './tasks.component.scss'
})
export class TasksComponent {

  private LocationList:AuditLocation[]=[];
  public FilteredLocations:AuditLocation[]=[];
  public SelectedLocation:AuditLocation = new AuditLocation();
  public LocationSearch:string = "";
  private AuditList:BasicAuditReturn[]=[];
  public FilteredAudits:BasicAuditReturn[]=[];
  public SelectedAudit:BasicAuditReturn = new BasicAuditReturn();
  public AuditTypes:DDL[]=[];
  public Frequencies:DDL[]=[];
  public SelectedAuditType:DDL=new DDL();
  public SelectedFrequency:DDL=new DDL();

  public ShowAuditSearch:boolean = true;
  public ShowLocationSearch:boolean = true;
  public ShowFrequencySearch:boolean = true;
  public ShowIncompleteOnly:boolean = false;
  public ShowCompleteOnly:boolean = false;
  
  public TasksLoaded:boolean = false;

  public NoValidAudit:boolean = false;
  public DisableFetchButton:boolean = true;

  private originalReturn:TasksResult = new TasksResult();

  private auditListReturn:BasicAuditResult= new BasicAuditResult();

  private TaskList:TaskInfo[]=[];
  public DisplayList:TaskInfo[]=[];

  private LocalAudits:UserLocationSave[]=[];

  constructor(private taskService:TasksService, private auditService:AuditService){
    this.getAuditList();
    this.getLocalAudits();
  }
  getLocalAudits(){
    this.auditService.getLocalAudits().pipe(tap(res=>{
      res.forEach(e=>{
        this.LocalAudits.push(new UserLocationSave(e));
      })
    })).subscribe();
  }

    getTasks(){
      this.DisableFetchButton = true;
      this.taskService.getTasks(this.SelectedAudit.AuditID,this.SelectedLocation.LocationID).pipe(
          tap(res => {
            this.DisableFetchButton = false;

            if(res.IsError){

            }else{
              this.originalReturn = new TasksResult(res);
              this.createTaskDisplay();
              this.TasksLoaded = true;
              this.collapseOptions()
            }
      
          })
        ).subscribe()
    }   
    isCompleted(test:boolean):string{
      if(test){
        return "Complete";
      }else{
        return "Due";
      }
    }
    createTaskDisplay(){
      this.TaskList = [];
      this.originalReturn.Tasks.forEach(e=>{
        this.TaskList.push(new TaskInfo(e));
      })
      this.TaskList.sort((a,b)=>{return Number(a.CompletedInPeriod) - Number(b.CompletedInPeriod) || a.Frequency.Seq - b.Frequency.Seq || a.AuditName.localeCompare(b.AuditName)});
      this.DisplayList = this.TaskList;
    }
    getAuditList(){
      this.taskService.getTaskAudits().pipe(
        tap(res => {
          if(res.IsError){
            console.log(res.ErrorCode);
          }else{
            this.auditListReturn = new BasicAuditResult(res);
            this.createAuditList();
            this.getLocationList();
          }    
        })
      ).subscribe()
    } 
    getLocationList(){
      this.taskService.getTaskLocations().pipe(
        tap(res => {
          this.createLocationList(res);    
          this.getTasks();
        })
      ).subscribe()
    } 
    createLocationList(response:AuditLocation[]){
      this.LocationList = [];
   
      response.forEach(e=>{
        if(this.LocationList.findIndex(f=>{return f.LocationID == e.LocationID}) == -1){
          this.LocationList.push(new AuditLocation(e));
        }
      });
      this.LocationList.sort((a,b)=>{return a.LocationName.localeCompare(b.LocationName)})
      this.LocationList.unshift(new AuditLocation({"LocationID":-1,"LocationName":"All Locations"}));
      if(this.LocationList.length>5){
        this.SelectedLocation = this.LocationList[1];
      }else{
        this.SelectedLocation = this.LocationList[0];
      }
      
      this.FilteredLocations = this.LocationList;
    }
    createAuditList(){
      this.AuditList=[];
      this.auditListReturn.Audits.forEach(e=>{
        if(this.AuditList.findIndex(f=>{return f.AuditID == e.AuditID}) == -1){
          this.AuditList.push(new BasicAuditReturn(e));
        }
      })
      this.AuditList.sort((a,b)=>{return a.AuditName.localeCompare(b.AuditName)});

      this.AuditList.unshift(new BasicAuditReturn({"AuditID":-1,"AuditName":"All Audits"}));
      this.SelectedAudit = this.AuditList[0];
      this.FilteredAudits = this.AuditList;
      this.createAuditFilters();
      
    }
    createAuditFilters(){
      this.AuditTypes = [];
      this.Frequencies = [];
      this.AuditList.forEach(e=>{
        if(e.AuditID!=-1){
          if(this.AuditTypes.findIndex(f=>{return f.ID == e.AuditType.ID}) ==-1 && e.AuditType.Description.length>0){
            this.AuditTypes.push(e.AuditType);
          }
          if(this.Frequencies.findIndex(f=>{return f.ID == e.AuditFrequency.ID}) == -1 && e.AuditFrequency.Description.length>0){
            this.Frequencies.push(e.AuditFrequency);
          }
        }
      })
      this.AuditTypes.sort((a,b)=>{return a.Description.localeCompare(b.Description)});
      var allType = new DDL({"ID":-1,"Description":"All Audit Types"})
      var allFreq= new DDL({"ID":-1,"Description":"All Frequencies"})
      this.AuditTypes.unshift(allType);
      this.SelectedAuditType = allType;
      this.Frequencies.sort((a,b)=>{return a.Seq=b.Seq});
      this.Frequencies.unshift(allFreq);
      this.SelectedFrequency = allFreq;
    }
   

    updateAudits(){
      this.DisableFetchButton = false;
      this.NoValidAudit = false;
      if(this.SelectedFrequency.ID == -1 && this.SelectedAuditType.ID == -1){
        this.FilteredAudits = this.AuditList;
        this.checkValidAudits();
        return;
      }
      if(this.SelectedFrequency.ID != -1 && this.SelectedAuditType.ID == -1){
        this.FilteredAudits = this.AuditList.filter(e=>{return e.AuditFrequency.ID == this.SelectedFrequency.ID});
        this.checkValidAudits();
        return;
      }
      if(this.SelectedFrequency.ID == -1 && this.SelectedAuditType.ID != -1){
        this.FilteredAudits = this.AuditList.filter(e=>{return e.AuditType.ID == this.SelectedAuditType.ID});
        this.checkValidAudits();
        return;
      }
      this.FilteredAudits = this.AuditList.filter(e=>{return e.AuditType.ID == this.SelectedAuditType.ID && e.AuditFrequency.ID == this.SelectedFrequency.ID});
     this.checkValidAudits();
    }
    checkValidAudits(){
      if(this.FilteredAudits.length == 0){
        this.DisableFetchButton = true;
        this.NoValidAudit = true;
      }else{
        var check = false;
        if(this.FilteredAudits.findIndex(e=>e.AuditID == this.SelectedAudit.AuditID) == -1){
          this.SelectedAudit = this.FilteredAudits[0];
        }
      }
    }
    doLocationSearch(){
      if(this.LocationSearch.length == 0){
        this.FilteredLocations = this.LocationList;
        return;
      }
      this.FilteredLocations = this.LocationList.filter(e=>{return e.LocationName.toLowerCase().includes(this.LocationSearch.toLowerCase())})
    }
    changeAuditSearchDisplay(){
      this.ShowAuditSearch = !this.ShowAuditSearch;
    }
    changeLocationSearchDisplay(){
      this.ShowLocationSearch = !this.ShowLocationSearch;
    }
    changeFrequencySearchDisplay(){
      this.ShowFrequencySearch = !this.ShowFrequencySearch;
    }
    collapseOptions(){
      this.ShowAuditSearch = false;
      this.ShowLocationSearch = false;
      this.ShowFrequencySearch = false;
    }
    updateCompleteOnly(val:boolean){
      if(val){
        this.ShowCompleteOnly = false;
        if(this.TaskList.length>0){
          this.DisplayList = this.TaskList.filter(f=>{return f.CompletedInPeriod == false})
        }
      }else{
        if(this.TaskList.length>0){
          this.DisplayList = this.TaskList;
        }
      }
    }
    updateIncompleteOnly(val:boolean){
      if(val){
        this.ShowIncompleteOnly = false;
        if(this.TaskList.length>0){
          this.DisplayList = this.TaskList.filter(f=>{return f.CompletedInPeriod})
        }
      }else{
        if(this.TaskList.length>0){
          this.DisplayList = this.TaskList;
        }
      }
    }
    getAllLocalAudits(t:TaskInfo):UserLocationSave[]{
   
      return this.LocalAudits.filter(f=>{return f.FlattenedQuestions[0].AuditID == t.AuditID && f.Location.LocationID == t.Location.LocationID})
    }
    
}
