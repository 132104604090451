import { QuestionAttachment } from "../question-attachment";
import { BasicAction } from "./basic-action";

export class AuditResultQuestion{
    public QuestionID:number;
    public AuditResultID:number;
    public QuestionTypeID:number;
    public QuestionHeader:string;
    public QuestionBody:string;
    public QuestionResult:string;
    public QuestionComments:string;
    public Score:number;
    public MaxScore:number;
    public Actions:BasicAction[];
    public QuestionAttachments: QuestionAttachment[];
    public Photos:string[];
    public ComplianceLimit:number = -1;
    public AllowNA:boolean = false;
    public NASelected:boolean = false;
    public QuestionAnswered:boolean = false;
    public ExcludeScoreFromTotal:boolean = false;
    constructor(values: Object = {}){
        Object.assign(this, values);   
        if(values.hasOwnProperty('Actions')){
            this.Actions = [];
            ((values as AuditResultQuestion)['Actions'] as BasicAction[]).forEach(element => {
                this.Actions.push(new BasicAction(element));
            });
        }
        if(values.hasOwnProperty('Actions')){
            this.QuestionAttachments = [];
            ((values as AuditResultQuestion)['QuestionAttachments'] as QuestionAttachment[]).forEach(element => {
                this.QuestionAttachments.push(new QuestionAttachment(element));
            });
        }
    }
}