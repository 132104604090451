<div *ngFor="let aud of Audits">
    <div *ngFor="let audInner of aud.FlattenedQuestions"
        class=" border-2 border-solid border-green-700 my-1 w-full  px-4 py-2 mb-4 rounded-md shadow-[0px_0px_7px_-2px_rgba(0,0,0,0.5)]">
        <div class="flex flex-col lg:flex-row justify-between mb-2">
            <h3>{{audInner.AuditName}}</h3>
            <h4>{{aud.Location.LocationName}}</h4>
        </div>
        <div class="border-t-[1px] border-t-grey-300 w-full"></div>
        <div class="my-2">
            <p>Started: <strong>{{util.humanDateDistance(audInner.AuditCompletionInfo.StartedDate)}}</strong></p>
            <p>Incomplete Questions: <strong>{{auditValidation.remainingQuestions(audInner)}}</strong></p>
            <p *ngIf="hasReferenceQuestion(audInner)">Reference: <strong>{{getReference(audInner)}}</strong></p>
            <p *ngIf="auditValidation.remainingQuestions(audInner,true)>0">Incomplete Required Questions:
                <strong>{{auditValidation.remainingQuestions(audInner,true)}} </strong></p>
            <p>Ready to submit: <strong>{{readyToSubmit(audInner).toString()}}</strong></p>
        </div>

        <div *ngIf="!onDeleteList(aud)" class="flex justify-end">
            <button class="btn-primary" (click)="changeDeleteConfirmStatus(aud)">Delete</button>
            <button class="btn-primary ml-3" (click)="jumpToAudit(audInner,aud.Location,aud.AuditGUID)">Edit</button>
            <!--<button class="btn-primary ml-3" (click)="submitAudit(audInner,aud.Location);"
                [disabled]="!auditValidation.validCompleteQuestions(audInner)">Submit</button>-->
        </div>
        <div *ngIf="onDeleteList(aud)"  class="my-6 p-3 border-grey-300 border-2 rounded-md mx-4">
            <h4>Confirm Audit Removal</h4>
            <p>Once the audit has been removed from your current audits it <strong>cannot be recovered!</strong></p>
            <div class="flex my-5">                           
                <button class="btn-primary btn-negative mr-2" (click)="removeAudit(aud.AuditGUID)" [disabled]="currentlyDeleting"><span *ngIf="!currentlyDeleting">Confirm Delete</span><span *ngIf="currentlyDeleting">Updating...</span></button>
                <button class="btn-primary btn-warning " (click)="changeDeleteConfirmStatus(aud)">Cancel</button>
            </div>

        </div>

    </div>
</div>