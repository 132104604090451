<div class="mb-2 border-grey-500 border-[1px] rounded-md p-3">
    <div class="flex justify-between">
        <div class="flex-col flex">
            <div class="flex items-baseline">
                <p class="font-bold">{{task.AuditName}} </p><span class="text-lg"> &#00;&#64;&#00; </span>
                <p class="font-bold text-green-700">{{task.Location.LocationName}}</p>
            </div>

            <p>{{frequencyString()}}</p>
            <div class="mt-2">
                <p>Due: {{periodEndString()}}</p>
                <p *ngIf="task.FrequencyMultiplier>1">{{task.TotalCompletedInPeriod}} out of
                    {{task.FrequencyMultiplier}} completed in period.</p>
                <p>Last Completed: {{lastCompleted()}}</p>

            </div>
        </div>
        <div *ngIf="!showAuditStartOptions">
            <span class="material-symbols-outlined text-grey-900  pr-2 text-2xl"
                *ngIf="task.CompletedInPeriod">assignment_turned_in</span>
            <span *ngIf="!task.CompletedInPeriod" (click)="checkExistingAudits()"
                class="material-symbols-outlined cursor-pointer pr-2 text-green-400 text-2xl"> assignment_add</span>
        </div>
        <div *ngIf="showAuditStartOptions">
            <span class="material-symbols-outlined  cursor-pointer text-grey-900  pr-2 text-2xl "
                (click)="collapseAuditStartOptions()">keyboard_arrow_up</span>
        </div>

    </div>
    <div *ngIf="showAuditStartOptions" class="p-4">
        <div *ngIf="LocalAudits.length>0">
            <h5>Current Audits</h5>
            <table class=" table-auto bg-standardwhite p-2 mt-1 ">         
                <tbody>
                    <tr *ngFor="let a of LocalAudits">
                        <td class="p-1">
                            {{humaniseDate(a.FlattenedQuestions[0].AuditCompletionInfo.StartedDate)}}<span *ngIf="getReferenceQuestion(a.FlattenedQuestions[0]).length>0"> &#40;{{getReferenceQuestion(a.FlattenedQuestions[0])}}&#41;</span>
                        </td>
                        <td (click)="continueAudit(a)" class="underline hover:cursor-pointer hover:text-green-700">Continue ></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-3">
            <button class="btn-primary-full" (click)="startNewAudit()" [disabled]="disableStart"><span *ngIf="disableStart">Loading Audit...</span><span *ngIf="!disableStart">Start Audit</span></button>
        </div>
    </div>
</div>