import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { BasicUser } from 'src/app/core-module/models/basic-user';
import { LocationHolder } from 'src/app/core-module/models/Locations/location-holder';
import { LocationResult } from 'src/app/core-module/models/Locations/location-result';
import { UserLocation } from 'src/app/core-module/models/UserManagement/user-location';
import { UserLocationsHolder } from 'src/app/core-module/models/UserManagement/user-locations-holder';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';

@Component({
  selector: 'app-assign-locations',
  templateUrl: './assign-locations.component.html',
  styleUrl: './assign-locations.component.scss'
})
export class AssignLocationsComponent {

  public isGroup:boolean = false;
  public originalData:UserLocationsHolder = new UserLocationsHolder();
  public selectedLocation:UserLocation = new UserLocation();
  public loadedLocation:UserLocation;
  public possibleLocations:UserLocation[] = [];
  public isLocationSelected:boolean = false;
  public isSaving:boolean = false;

  public showLocation:boolean = true;
  public topLevel:boolean = true;
  public locations: UserLocationsHolder = new UserLocationsHolder();
  public selectedLocations: UserLocation[] = [];
  public minLevelID: number;
  public minSeq:number;

  public locationsReady:boolean = false;

  public completeUserList: BasicUser[] = [];
  public filteredUserList:BasicUser[]=[];
  public filterUser:string = "";

  constructor(private UMS: UserManagementService, private router: Router, private toastr:ToastrService) {
    if (this.router.url == '/settings/locations/assigngrouplocations') {
      this.isGroup = true;
    }
    this.getInitialData();
    
  }
  refreshPage(completeRefresh:boolean){
    if(completeRefresh){
      this.getInitialData(); 
      this.resetLocations();
      this.isLocationSelected = false;
    }
 }
 getInitialData(){
  this.UMS.getUserLocationsList(this.isGroup).pipe(tap((a) => {this.originalData = a;this.setMinLevel();this.initialList()})).subscribe();
 }

 initialList() {
  this.possibleLocations = [];
  this.topLevel = true;

  //this.possibleLocations.push(new UserLocation({HasPermission:false,LocationName:"Please select...",LocationID:-1}))
  //this.selectedLocation = this.possibleLocations[0];
  this.originalData.UserLocations.forEach(e => {
    if (e.LocationLevelID == this.minLevelID) {
      this.possibleLocations.push(new UserLocation(e));
    }
    this.locationsReady = true;
  })
  //(this.possibleLocations);
}
  setMinLevel() {
    this.minSeq = this.originalData.UserLocations[0].LocationLevelSeq;
    this.minLevelID = this.originalData.UserLocations[0].LocationLevelID;
    this.originalData.UserLocations.forEach(e => {
      if (e.LocationLevelSeq < this.minSeq) {
        this.minSeq = e.LocationLevelSeq;
        this.minLevelID = e.LocationLevelID;
      }
    })
    
  }
 resetLocations(){
  this.initialList();
 }
 locationHasChildren(location:UserLocation){
  //return true;
  return this.originalData.UserLocations.findIndex(x=>x.ParentLocationID == location.LocationID) != -1;
 }
 selectLocation(selectedLocation:UserLocation){
  this.filterUser = "";
  if (selectedLocation.HasPermission ==true) {
    this.selectedLocation = selectedLocation;
    this.isLocationSelected = true;
    this.loadedLocation = this.selectedLocation;
    this.combineUsers();
  }
 }
 chooseSublocation(selectedLocation:UserLocation){}
  buildLocationDDL(parentID:number){
    this.possibleLocations = [];
    if(parentID!= -1){
      this.topLevel = false;
    }else{
      this.topLevel = true;
    }
    //this.possibleLocations.push(new UserLocation({HasPermission:false,LocationName:"Please select...",LocationID:-1}))
    //this.selectedLocation = this.possibleLocations[0];
    this.originalData.UserLocations.forEach(e=>{
      if(e.ParentLocationID == parentID){
         this.possibleLocations.push(new UserLocation(e));
      }

      this.locationsReady = true;
    })
  }
  combineUsers(){
    this.completeUserList = [];
    this.originalData.PossibleUsers.forEach(x=>{
      this.completeUserList.push(new BasicUser(x));
    })
    this.loadedLocation.Users.forEach(x=>{
      x.Selected = false;
      if(this.completeUserList.findIndex(y=> y.UserID==x.UserID) == -1){
        x.Selected = true;
        this.completeUserList.push(x);
      }else{
        this.completeUserList.find(y=>y.UserID == x.UserID)!.Selected = true;
      }
    })
    this.completeUserList.sort((a, b) => a.DisplayName.localeCompare(b.DisplayName));
    this.filteredUserList = this.completeUserList;
    
  }
  filterListByUser(){
    this.filteredUserList = this.completeUserList.filter(e=>e.DisplayName.toLowerCase().includes(this.filterUser.toLowerCase()));
  }
  loadLocation() {
    if (this.selectedLocation.HasPermission ==true) {
      this.isLocationSelected = true;
      this.loadedLocation = this.selectedLocation;
      this.combineUsers();
    }

  }
  save(){
    this.isSaving = true;
    this.loadedLocation.Users = [];
    var userTypeID = 1;
    if(this.isGroup){
      userTypeID =2;
    }
    this.filteredUserList.forEach(x=>{
      if(x.Selected){
        x.UserTypeID = userTypeID;
        this.loadedLocation.Users.push(x);
      }
    })
    this.UMS.saveUserLocationChange(this.loadedLocation).pipe(
      tap(a=>{
        this.refreshPage(a);
        this.toastr.addToastr(new Toastr({Message: "Changes saved.", Type: 2}))
        this.isSaving = false;
      }
    )).subscribe();
    
    //save changes
  }
  
  reset(){
    this.loadLocation();
  }

  resetSelectLocation(){
    this.isLocationSelected = false;
    this.selectedLocation = new UserLocation();
  }
  goBack(){
    this.router.navigateByUrl("/settings");
  }
}
