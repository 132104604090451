import { Injectable } from '@angular/core';
import { format, formatDistance, parse, parseISO } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  private _isOnline: boolean;
  get isOnline(): boolean {
    return this._isOnline;
  }
  set isOnline(value:boolean){
    this._isOnline = value;
  }

  stringHasLowerCase(value: string): boolean {
    return value.toUpperCase() != value;
  }

  stringHasUpperCase(value: string): boolean {
    return value.toLowerCase() != value;
  }

  stringHasNumber(value: string): boolean {
    return /\d/.test(value);
  }

  stringHasSpecialCharacter(value: string): boolean {
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    return format.test(value);
  }
  newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  humanDate(date: Date): string {

    var correctedDate = new Date(date);
    if (typeof correctedDate === 'object' && correctedDate !== null && 'toISOString' in correctedDate && !isNaN(correctedDate.getTime())) {
      if (correctedDate) {
        var correctedDate: Date = parseISO(correctedDate.toISOString());
        return format(correctedDate, 'do MMM yyyy')
      }
    }
    return "";

  }
  shortHumanDate(date:Date):string{
    var correctedDate = new Date(date);
    if (typeof correctedDate === 'object' && correctedDate !== null && 'toISOString' in correctedDate && !isNaN(correctedDate.getTime())) {
      if (correctedDate) {
        var correctedDate: Date = parseISO(correctedDate.toISOString());
        return format(correctedDate, 'do MMM')
      }
    }
    return "";
  }
  humanDateDistance(date: Date): string {
    var correctedDate = new Date(date);
    if (typeof correctedDate === 'object' && correctedDate !== null && 'toISOString' in correctedDate) {
      if (correctedDate) {
        var correctedDate: Date = parseISO(correctedDate.toISOString());
        return formatDistance(correctedDate, new Date(), { addSuffix: true })
      }
    }
    return "";
  }
  calcPercentage(numerator:number,denominator:number):number{
    if(numerator>0 && denominator>=0){
      return Math.floor((numerator/denominator)*100);
    }else{
      return 0;
    }
  }

  removeTimezoneFromDate(date:Date){
    const hours = (date.getTimezoneOffset() / 60) * -1;
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const milliseconds = date.getMilliseconds();
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, seconds, milliseconds);
    
    return date;
  }
  validEmail(email: string) {
    var reg = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    return reg.test(email);

  }
  hasWhiteSpace(s:string) {
    return s.indexOf(' ') >= 0;
  }
  stripTime(input: Date): Date {
    input = new Date(input);
    input.setHours(0, 0, 0, 0);
    return input;
  }
  limitCharacters(value:string,chars:number){
    if(value.length<chars+3){
      return value;
    }
    return value.substring(0,chars) + '...';
  }
}
