<div class="lg:px-5 px-2 my-2 ">
    <h1 class="p-4">Tasks</h1>
    <div class="mt-5">
        <div class=" mb-2 border-grey-500 border-[1px] rounded-md p-3">
            <div class="flex justify-between  hover:cursor-pointer" (click)="changeAuditSearchDisplay()">
                <div class="flex">
                    <p class="text-lg">Audits</p>
                    <span class="material-symbols-outlined cursor-pointer pr-2 text-xl ml-2"
                        *ngIf="!ShowAuditSearch">edit</span>
                </div>

                <p class="font-bold" *ngIf="!ShowAuditSearch">{{SelectedAuditType.Description}} -
                    {{SelectedAudit.AuditName}}</p>
            </div>

            <div *ngIf="ShowAuditSearch">
                <div class="flex-col flex lg:flex-row justify-between">
                    <select class="text-box w-full md:max-w-xl mt-2 mx-2 standardTextSize"
                        [(ngModel)]="SelectedAuditType" (change)="updateAudits()">
                        <option *ngFor="let at of AuditTypes" [ngValue]="at">{{at.Description}}</option>
                    </select>
                    <select class="text-box w-full md:max-w-xl mt-2 mx-2 standardTextSize" [(ngModel)]="SelectedAudit">
                        <option *ngFor="let a of FilteredAudits " [ngValue]="a">{{a.AuditName}}</option>
                    </select>
                </div>

            </div>
        </div>
        <div class=" mb-2 border-grey-500 border-[1px] rounded-md p-3 ">
            <div class="flex justify-between hover:cursor-pointer" (click)="changeLocationSearchDisplay()">
                <div class="flex">
                    <p class="text-lg ">Locations</p>
                    <span class="material-symbols-outlined cursor-pointer pr-2 text-xl ml-2"
                        *ngIf="!ShowLocationSearch">edit</span>
                </div>

                <p class="font-bold" *ngIf="!ShowLocationSearch">{{SelectedLocation.LocationName}}</p>
            </div>

            <div class="flex-col flex lg:flex-row justify-between" *ngIf="ShowLocationSearch">
                <select class="text-box  mt-2 mx-2" [(ngModel)]="SelectedLocation">
                    <option *ngFor="let a of FilteredLocations" [ngValue]="a">
                        {{a.LocationName}}</option>
                </select>
                <input type="text" class="text-box w-full  lg:mx-0 mt-2 mx-2" [(ngModel)]="LocationSearch"
                    placeholder="Location Search" (keyup)="doLocationSearch()" />
            </div>

        </div>
        <div class=" mb-2 border-grey-500 border-[1px] rounded-md p-3 ">
            <div class="flex justify-between hover:cursor-pointer" (click)="changeFrequencySearchDisplay()">
                <div class="flex">
                    <p class="text-lg ">Frequency</p>
                    <span class="material-symbols-outlined cursor-pointer pr-2 text-xl ml-2"
                        *ngIf="!ShowFrequencySearch">edit</span>
                </div>

                <p class="font-bold" *ngIf="!ShowFrequencySearch">{{SelectedFrequency.Description}}</p>
            </div>


            <select class="text-box w-full md:max-w-xl mx-2 lg:mx-0 mt-2 standardTextSize" *ngIf="ShowFrequencySearch"
                [(ngModel)]="SelectedFrequency" (change)="updateAudits()">
                <option *ngFor="let f of Frequencies" [ngValue]="f">{{f.Description}}</option>
            </select>
        </div>
        <div class=" mb-2 py-3">
            <div class="mt-4 flex flex-col  ">


                <button class="btn-primary w-full mt-4 text-xl "
                    [ngClass]="{'hover:cursor-not-allowed':DisableFetchButton,'hover:cursor-pointer':!DisableFetchButton}"
                    (click)="getTasks()" [disabled]="DisableFetchButton"><span *ngIf="DisableFetchButton">Fetching Tasks...</span><span *ngIf="!DisableFetchButton">Get Tasks</span>
                    </button>
                <div class="flex justify-center">
                    <p class="text-red-600 mt-2" *ngIf="NoValidAudit">No audits available for the given frequency and
                        type.</p>
                </div>

                <div class="flex flex-col lg:flex-row lg:justify-end">
                    <div class="flex justify-start  items-center mt-4 self-end mr-4" *ngIf="TasksLoaded">
                        <p>Show Incomplete Only:</p><app-toggle-switch (checkedChange)="updateCompleteOnly($event)"
                            [(checked)]="ShowIncompleteOnly" class="ml-2"></app-toggle-switch>
                    </div>
                    <div class="flex justify-start  items-center mt-4 self-end" *ngIf="TasksLoaded">
                        <p>Show Complete Only:</p><app-toggle-switch (checkedChange)="updateIncompleteOnly($event)"
                            [(checked)]="ShowCompleteOnly" class="ml-2"></app-toggle-switch>
                    </div>
                </div>

            </div>

        </div>
        <div *ngIf="TasksLoaded">
            <div *ngFor="let t of DisplayList" class=" ">
                <app-task-detail [task]="t" [LocalAudits]="getAllLocalAudits(t)"></app-task-detail>
            </div>
        </div>
    </div>
</div>