import { DDL } from "../ddl";

export class LocationAudit {
    LocationID:number = -1;
    AuditID:number = -1;
    AuditName:string = "";
    AssignedToLocation:boolean = false;
    AuditType:string = "";
    AuditTypeID:number = -1;
    ShowFrequencyUpdate: boolean = false;
    Frequency:DDL;
    FrequencyMultiplier: number;
    constructor(values: Object = {}) {
    Object.assign(this, values);
    }
}