import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { format } from 'date-fns';
import { tap } from 'rxjs';
import { BasicAction } from 'src/app/core-module/models/Audits/AuditResults/basic-action';
import { BasicAuditReturn } from 'src/app/core-module/models/Audits/AuditResults/basic-audit-return';
import { DDL } from 'src/app/core-module/models/ddl';
import { AuditLocation } from 'src/app/core-module/models/Locations/audit-location';
import { ReportData } from 'src/app/core-module/models/Reports/report-data';
import { ReportDetail } from 'src/app/core-module/models/Reports/report-detail';
import { ReportFilters } from 'src/app/core-module/models/Reports/report-filters';
import { ReportFiltersResult } from 'src/app/core-module/models/Reports/report-filters-result';
import { ReportRequest } from 'src/app/core-module/models/Reports/report-request';
import { MissedAuditRow } from 'src/app/core-module/models/Reports/ReportingRows/missed-audit-row';
import { MissedAuditRowDetail } from 'src/app/core-module/models/Reports/ReportingRows/missed-audit-row-detail';
import { NonComplianceRow } from 'src/app/core-module/models/Reports/ReportingRows/non-compliance-row';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { ExportService } from 'src/app/core-module/services/export.service';
import { ReportingService } from 'src/app/core-module/services/reporting.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UtilService } from 'src/app/core-module/services/util.service';

@Component({
  selector: 'app-reports',

  templateUrl: './reports.component.html',
  styleUrl: './reports.component.scss'
})
export class ReportsComponent {

  public SelectedAudit: BasicAuditReturn = new BasicAuditReturn();
  public SelectedFrequency: DDL = new DDL();
  public SelectedAuditType: DDL = new DDL();
  public SelectedReport: ReportDetail = new ReportDetail();
  public SelectedLocation: AuditLocation = new AuditLocation();
  public IncludeSubLocations: boolean = false;

  public collapseAuditFilter: boolean = false;
  public collapseLocationFilter: boolean = false;
  public collapseOptionsFilter: boolean = false;

  public showFilters: boolean = true;
  public StartDate: Date = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate());
  public EndDate: Date = new Date();
  public Frequencies: DDL[] = [];
  private _Frequencies: DDL[] = [];
  public Reports: ReportDetail[] = [];
  public AuditTypes: DDL[] = [];
  public Audits: BasicAuditReturn[] = [];
  public DisplayAudits: BasicAuditReturn[] = [];
  public Locations: AuditLocation[] = [];
  public LocationLevels: DDL[] = [];

  public FetchingReport: boolean = false;
  public NoData: boolean = false;
  public FetchingExport: boolean = false;
  //report storage
  //**Non-compliance** */
  private _NonComplianceRows: NonComplianceRow[] = [];
  public DisplayNonComplianceRows: NonComplianceRow[] = [];
  private CurrentNonComplianceOrder: number = -1;
  public selectedNonComplianceRow: NonComplianceRow = new NonComplianceRow();
  public showNonComplianceDetails: boolean = false;
  //**Audit Completion** */
  private _AuditCompletionRows: MissedAuditRow[] = [];
  public DisplayAuditCompletionRows: MissedAuditRow[] = [];
  private CurrentAuditCompletionOrder: number = -1;
  public selectedAuditCompletionRow: MissedAuditRow = new MissedAuditRow();
  public showAuditCompletionDetails: boolean = false;
  public showOnlyAuditCompletionIssueRows: boolean = false;

  constructor(private reportingService: ReportingService, private util: UtilService, private toast: ToastrService, private ex: ExportService) {
    this.getFilters()
  }
  getFilters() {
    this.reportingService.getReportFilters().pipe(
      tap(res => {
        if (res.IsError) {

        } else {
          this.setFilters(res.Filters);
        }
      })
    ).subscribe();
  }
  setFilters(filters: ReportFilters) {
    this.AuditTypes = [];
    this.AuditTypes.push(new DDL({ ID: -1, Description: "All Audit Types" }));
    this.SelectedAuditType = this.AuditTypes[0];
    if (filters.AuditTypes.length > 0) {
      filters.AuditTypes.forEach(e => {
        this.AuditTypes.push(new DDL(e));
      });
    }
    this._Frequencies = [];
    this._Frequencies.push(new DDL({ ID: -1, Description: "All Audit Frequencies" }));
    if (filters.Frequencies.length > 0) {
      filters.Frequencies.forEach(e => {
        this._Frequencies.push(new DDL(e));
      });
    }
    this.setFrequencies(false);
    this.SelectedFrequency = this.Frequencies[0];
    /*if (filters.Frequencies.length > 0) {
      filters.Frequencies.forEach(e => {
        this.Frequencies.push(new DDL(e));
      });
    }*/
    this.Audits = [];
    this.Audits.push(new BasicAuditReturn({ AuditID: -1, AuditName: "All Audits" }))
    this.SelectedAudit = this.Audits[0];
    if (filters.Audits.length > 0) {
      filters.Audits.forEach(e => {
        this.Audits.push(new BasicAuditReturn(e));
      })
      this.updateAudits();
    }
    this.Reports = [];
    if (filters.ReportList.length > 0) {
      filters.ReportList.forEach(e => {
        this.Reports.push(new ReportDetail(e));
      })
      this.SelectedReport = this.Reports[0];
    }
    this.Locations = [];
    this.Locations.push(new AuditLocation({ LocationID: -1, LocationName: "All Locations" }))
    if (filters.Locations.length > 0) {
      filters.Locations.forEach(e => {
        this.Locations.push(new AuditLocation(e));
      })
    }
    this.SelectedLocation = this.Locations[0];
    this.updateReportControls();
  }
  setFrequencies(restricted: boolean) {
    this.Frequencies = [];
    this._Frequencies.forEach(e => {
      if (restricted) {
        if (e.ID != 1 && e.ID != 4) {
          this.Frequencies.push(e)
        }

      } else {
        this.Frequencies.push(e)
      }

    });
    if (this.Frequencies.findIndex(e => e.ID == this.SelectedFrequency.ID) == -1) {
      this.SelectedFrequency = this.Frequencies[0];
    }

  }
  getReport() {
    this.NoData = false;
    this.FetchingReport = true;
    this.clearReportData();
    var r: ReportRequest = new ReportRequest();
    r.ReportID = this.SelectedReport.ID;
    r.StartDate = this.StartDate;
    r.EndDate = this.EndDate;
    r.AuditIDs = [];
    r.AuditIDs.push(this.SelectedAudit.AuditID);
    r.FrequencyID = this.SelectedFrequency.ID;
    r.LocationIDs = [];
    r.LocationIDs.push(this.SelectedLocation.LocationID);
    r.AuditTypeID = this.SelectedAuditType.ID;
    r.IncludeChildren = this.IncludeSubLocations;
    this.reportingService.getReport(r).pipe(
      tap(res => {
        if (res.IsError) {
          if (res.ErrorMessage == 'No data') {
            this.NoData = true;
          } else {
            this.toast.addToastr(new Toastr({ Type: 3, Message: "Unable to return to selected data, please contact support if this continues." }));
          }
          this.FetchingReport = false;
        } else {
          this.storeTable(res.ReportingData);
        }
      })
    ).subscribe();
  }
  clearReportData() {
    this._NonComplianceRows = [];
    this.DisplayNonComplianceRows = [];
    this.CurrentNonComplianceOrder = -1;

    this._AuditCompletionRows = [];
    this.DisplayAuditCompletionRows = [];
    this.CurrentAuditCompletionOrder = -1;
    this.showOnlyAuditCompletionIssueRows = false;

  }
  storeTable(res: ReportData) {
    console.log(res);
    switch (this.SelectedReport.ID) {

      case 1:
        res.NonComplianceRows.forEach(e => {
          this._NonComplianceRows.push(new NonComplianceRow(e));
        });
        this.resetNonComplianceData();
        break;
      case 2:
        res.MissedAuditRows.forEach(e => {
          this._AuditCompletionRows.push(new MissedAuditRow(e));
        });
        this.resetAuditCompletionRows();
        break;

    }
    this.FetchingReport = false;
  }
  /*getPercentageDisplay(r:NonComplianceRow):string{
    if(r.PercentageOfAudits == -1){
      return '-'
    }else {
      return r.PercentageOfAudits.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + "%"
    }
  }*/

  orderNCByAuditName() {
    if (this.CurrentNonComplianceOrder == 1) {
      this.DisplayNonComplianceRows.sort((a, b) => (b.Info.AuditName.localeCompare(a.Info.AuditName)))
      this.CurrentNonComplianceOrder = -1;
    } else {
      this.DisplayNonComplianceRows.sort((a, b) => (a.Info.AuditName.localeCompare(b.Info.AuditName)))
      this.CurrentNonComplianceOrder = 1;
    }

  }
  orderNCByQuestion() {
    if (this.CurrentNonComplianceOrder == 2) {
      this.DisplayNonComplianceRows.sort((a, b) => (b.Info.QuestionHeader.localeCompare(a.Info.QuestionHeader)))
      this.CurrentNonComplianceOrder = -1;
    } else {
      this.DisplayNonComplianceRows.sort((a, b) => (a.Info.QuestionHeader.localeCompare(b.Info.QuestionHeader)))
      this.CurrentNonComplianceOrder = 2;
    }
  }
  orderNCByNonCompliance() {
    if (this.CurrentNonComplianceOrder == 3) {
      this.DisplayNonComplianceRows.sort((a, b) => { if (b.NonCompliantCounter < a.NonCompliantCounter) { return 1 } return 0 });
      this.CurrentNonComplianceOrder = -1;
    } else {
      this.DisplayNonComplianceRows.sort((a, b) => { if (a.NonCompliantCounter < b.NonCompliantCounter) { return 1 } return 0 });
      this.CurrentNonComplianceOrder = 3;
    }
  }
  orderNCByCompletedAudits() {
    if (this.CurrentNonComplianceOrder == 4) {
      this.DisplayNonComplianceRows.sort((a, b) => { if (b.CompletedQuestionsCounter < a.CompletedQuestionsCounter) { return 1 } return 0 });
      this.CurrentNonComplianceOrder = -1;
    } else {
      this.DisplayNonComplianceRows.sort((a, b) => { if (a.CompletedQuestionsCounter < b.CompletedQuestionsCounter) { return 1 } return 0 });
      this.CurrentNonComplianceOrder = 4;
    }
  }
  orderNCByPercentage() {
    if (this.CurrentNonComplianceOrder == 5) {
      this.DisplayNonComplianceRows.sort((a, b) => { if (b.CompliancePerc < a.CompliancePerc) { return 1 } return 0 });
      this.CurrentNonComplianceOrder = -1;
    } else {
      this.DisplayNonComplianceRows.sort((a, b) => { if (a.CompliancePerc < b.CompliancePerc) { return 1 } return 0 });
      this.CurrentNonComplianceOrder = 5;
    }

  }
  orderAuditCompletionByAuditName() {
    if (this.CurrentAuditCompletionOrder == 1) {
      this.DisplayAuditCompletionRows.sort((a, b) => (b.Info.AuditName.localeCompare(a.Info.AuditName)))
      this.CurrentAuditCompletionOrder = -1;
    } else {
      this.DisplayAuditCompletionRows.sort((a, b) => (a.Info.AuditName.localeCompare(b.Info.AuditName)))
      this.CurrentAuditCompletionOrder = 1;
    }
  }
  orderAuditCompletionByFrequency() {
    if (this.CurrentAuditCompletionOrder == 2) {
      this.DisplayAuditCompletionRows.sort((a, b) => { if (b.Info.FrequencyID > a.Info.FrequencyID) { return 1 } return 0 })
      this.CurrentAuditCompletionOrder = -1;
    } else {
      this.DisplayAuditCompletionRows.sort((a, b) => { if (b.Info.FrequencyID < a.Info.FrequencyID) { return 1 } return 0 })
      this.CurrentAuditCompletionOrder = 2;
    }
  }
  orderAuditCompletionByCompletedAudits() {
    if (this.CurrentAuditCompletionOrder == 3) {
      this.DisplayAuditCompletionRows.sort((a, b) => { if (b.completedAudits > a.completedAudits) { return 1 } return 0 })
      this.CurrentAuditCompletionOrder = -1;
    } else {
      this.DisplayAuditCompletionRows.sort((a, b) => { if (b.completedAudits < a.completedAudits) { return 1 } return 0 })
      this.CurrentAuditCompletionOrder = 3;
    }
  }
  orderAuditCompletionByExpectedAudits() {
    if (this.CurrentAuditCompletionOrder == 4) {
      this.DisplayAuditCompletionRows.sort((a, b) => { if (b.expectedTotalAudits > a.expectedTotalAudits) { return 1 } return 0 })
      this.CurrentAuditCompletionOrder = -1;
    } else {
      this.DisplayAuditCompletionRows.sort((a, b) => { if (b.expectedTotalAudits < a.expectedTotalAudits) { return 1 } return 0 })
      this.CurrentAuditCompletionOrder = 4;
    }
  }
  orderAuditCompletionByCompletionPerc() {
    if (this.CurrentAuditCompletionOrder == 5) {
      this.DisplayAuditCompletionRows.sort((a, b) => { if (b.percAuditsCompleted > a.percAuditsCompleted) { return 1 } return 0 })
      this.CurrentAuditCompletionOrder = -1;
    } else {
      this.DisplayAuditCompletionRows.sort((a, b) => { if (b.percAuditsCompleted < a.percAuditsCompleted) { return 1 } return 0 })
      this.CurrentAuditCompletionOrder = 5;
    }
  }

  resetNonComplianceData() {
    this._NonComplianceRows.forEach(e => {
      if (this.DisplayNonComplianceRows.findIndex(f => f.Info.AuditQuestionID == e.Info.AuditQuestionID) != -1) {
        this.DisplayNonComplianceRows.find(g => g.Info.AuditQuestionID == e.Info.AuditQuestionID)?.SubNonComplianceRows.push(e);
        this.DisplayNonComplianceRows.find(g => g.Info.AuditQuestionID == e.Info.AuditQuestionID)!.CompletedQuestionsCounter += 1;
        if (!e.Compliant) {
          this.DisplayNonComplianceRows.find(g => g.Info.AuditQuestionID == e.Info.AuditQuestionID)!.NonCompliantCounter += 1;
        }
      } else {
        e.SubNonComplianceRows.push(e);
        e.CompletedQuestionsCounter = 1
        if (!e.Compliant) {
          e.NonCompliantCounter = 1;
        }
        this.DisplayNonComplianceRows.push(new NonComplianceRow(e));
      }
    });
    this.DisplayNonComplianceRows.forEach(e => {
      if (e.NonCompliantCounter == 0) {
        e.CompliancePerc = 0;
      } else {
        e.CompliancePerc = Math.round((e.NonCompliantCounter / e.CompletedQuestionsCounter) * 100);
      }
      e.SubNonComplianceRows.sort((a, b) => { if (a.Info.DateRecorded > b.Info.DateRecorded) { return 1 } return 0 })
      e.SubNonComplianceRows.forEach(f => {
        if (f.Actions.length > 0) {
          f.Actions.sort((a, b) => { if (a.Resolved > b.Resolved) { return 1 } return 0 })
        }
      })
    });
    this.DisplayNonComplianceRows.sort((a, b) => { if (a.NonCompliantCounter < b.NonCompliantCounter) { return 1 } return 0 });
  }
  resetAuditCompletionRows() {
    this._AuditCompletionRows.forEach(e => {
      console.log(e,e.Info.FrequencyMultiplier);
      var existingRow = this.DisplayAuditCompletionRows.find(f => f.Info.AuditID == e.Info.AuditID && f.Info.FrequencyID == e.Info.FrequencyID && f.Info.FrequencyMultiplier == e.Info.FrequencyMultiplier);

      if (existingRow) {
        
        existingRow.expectedTotalAudits += (e.Details.length*e.Info.FrequencyMultiplier);
        existingRow.completedAudits += this.completeAuditsCounter(e);
        existingRow.subRows.push(e);
      } else {
        e.subRows.push(e);
        e.expectedTotalAudits += (e.Details.length*e.Info.FrequencyMultiplier);
        e.completedAudits += this.completeAuditsCounter(e);
        this.DisplayAuditCompletionRows.push(new MissedAuditRow(e));
      }
    })
    this.DisplayAuditCompletionRows.forEach(e => {
      if (e.expectedTotalAudits > 0) {
        e.percAuditsCompleted = Math.min(Math.round((e.completedAudits / e.expectedTotalAudits) * 100),100);
      } else {
        e.percAuditsCompleted = 0;
      }
    })
    this.DisplayAuditCompletionRows.sort((a, b) => { return a.Info.AuditName.localeCompare(b.Info.AuditName) });
  }
  completeAuditsCounter(auditRow: MissedAuditRow): number {
    var returnCounter = 0;
    auditRow.Details.forEach(e => {
      if (e.CompletedInPeriod) {
        returnCounter+=e.TotalCompleted;
      }
    })
    return returnCounter;
  }
  auditCompletedShowOnlyIssue() {

  }
  auditCompletionDetails(rows: MissedAuditRowDetail[]): MissedAuditRowDetail[] {
    var returnRows: MissedAuditRowDetail[] = [];
    if (this.showOnlyAuditCompletionIssueRows) {
      rows.forEach(e => {
        if (!e.CompletedInPeriod) {
          returnRows.push(e)
        }

      })
      return returnRows;
    } else {
      return rows;
    }

  }
  updateAudits() {
    this.Audits.forEach(e => {
      e.Display = true;
    })
    if (this.SelectedAuditType.ID != -1) {
      this.Audits.forEach(e => {
        if (e.AuditID != -1) {
          if (e.AuditType.ID != this.SelectedAuditType.ID) {
            e.Display = false;
          }
        }

      })
    }
    if (this.SelectedFrequency.ID != -1) {
      this.Audits.forEach(e => {
        if (e.AuditID != -1) {
          if (e.AuditFrequency.ID != this.SelectedFrequency.ID) {
            e.Display = false;
          }
        }
      })
    }
    this.DisplayAudits = this.Audits.filter(e => e.Display);
    if (this.DisplayAudits.findIndex(e => { e.AuditID != this.SelectedAudit.AuditID }) == -1) {
      this.SelectedAudit = this.DisplayAudits[0];
    }
  }
  selectNonComplianceRow(NCR: NonComplianceRow) {
    this.selectedNonComplianceRow = NCR;
    this.showNonComplianceDetails = true;
    this.displaySelectRow();
  }
  selectActionCompletionRow(ACR: MissedAuditRow) {
    this.selectedAuditCompletionRow = ACR;
    this.showAuditCompletionDetails = true;
    this.displaySelectRow();
  }
  displaySelectRow() {
    this.showFilters = false;
    window.scrollTo(0, 0);
  }
  noncomplianceActionDisplayName(BA: BasicAction): string {
    if (BA.Resolved) {
      return BA.ResolvedBy;
    } else {
      return BA.AssignedTo;
    }
  }
  noncomplianceActionDate(BA: BasicAction): string {
    if (BA.Resolved) {
      return this.basicDate(BA.ResolvedDate);
    } else {
      return this.basicDate(BA.ActionDeadline);
    }
  }
  noncomplianceStatus(resolved: boolean): string {
    if (resolved) {
      return "Closed";
    } else {
      return "Open";
    }
  }
  returnToSummary() {
    this.showNonComplianceDetails = false;
    this.showAuditCompletionDetails = false;
    this.showOnlyAuditCompletionIssueRows = false;
    this.showFilters = true;
  }
  updateReportControls() {
    if (this.SelectedReport.ID == 2) {
      this.setFrequencies(true);
    } else {
      this.setFrequencies(false);
    }
  }

  /* exports */
  exportSelectedReport() {
    switch (this.SelectedReport.ID) {
      case 1:
        this.getNonComplianceExport();
        break;
      case 2:
        this.getAuditCompletionExport();
        break;
    }
  }
  getNonComplianceExport() {
    var RD: ReportData = new ReportData();
    RD.ReportID = 1;
    if (this.showFilters) {
      RD.ExportSummary = true;
      RD.NonComplianceRows = this.DisplayNonComplianceRows;
    } else {
      RD.ExportSummary = false;
      RD.NonComplianceRows = this.selectedNonComplianceRow.SubNonComplianceRows;
    }
    if(RD.NonComplianceRows.length>0){

      this.ex.exportReport(RD).subscribe();
    }else{
      this.toast.addToastr(new Toastr({ Type: 1, Message: "No data available for export." }));
    }

  }
  getAuditCompletionExport() {
    var RD: ReportData = new ReportData();
    RD.ReportID = 2;
    if (this.showFilters) {
      RD.ExportSummary = true;
      RD.MissedAuditRows = this.DisplayAuditCompletionRows;
    } else {
      RD.ExportSummary = false;
      RD.MissedAuditRows = this.selectedAuditCompletionRow.subRows;
      RD.MissedAuditRows.forEach(e=>{
        e.Details = this.auditCompletionDetails(e.Details);
      })
    }
    if(RD.MissedAuditRows.length>0){
      this.ex.exportReport(RD).subscribe();
    }else{
      this.toast.addToastr(new Toastr({ Type: 1, Message: "No data available for export." }));
    }
    
  }


  /* Filter Collapse*/
  auditFilterSwitch() {
    this.collapseAuditFilter = !this.collapseAuditFilter;
  }
  locationFilterSwitch() {
    this.collapseLocationFilter = !this.collapseLocationFilter;
  }
  optionsFilterSwitch() {
    this.collapseOptionsFilter = !this.collapseOptionsFilter;
  }


  humaniseDate(entryDate: Date): string {
    return this.util.humanDate(entryDate);
  }
  basicDate(entryDate: Date): string {
    return formatDate(entryDate, 'dd/MM/yy', 'en_GB');
  }
  auditPeriod(FrequencyID: number, start: Date, end: Date): string {
    switch (FrequencyID) {
      //daily
      case 2:
        return this.humaniseDate(start);
        break;
      //weekly
      case 3:
        return format(start, 'do') + ' - ' + format(end, 'do MMM yy');
        break;
      //monthly
      case 5:
        return format(start, 'MMM yy');
        break;
      //quarterly
      case 6:
        return format(start, 'MMM - ') + format(end, 'MMM yy');
        break;
      //semi annual
      case 7:
        return format(start, 'MMM - ') + format(end, 'MMM yy');
        break;
      //annual
      case 8:
        return format(start, 'MMM - ') + format(end, 'MMM yy');
        break;
    }
    return '';

  }
  frequencyDisplay(freqDescription:string,multi:number){
    if(multi == 1){
      return freqDescription;
    }else{
      return multi + 'x ' + freqDescription;
    }

  }
}
