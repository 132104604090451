import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TasksResult } from '../models/Tasks/TasksResult';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuditResult } from '../models/Audits/audit-result';
import { BasicAuditResult } from '../models/Audits/AuditResults/basic-audit-result';
import { AuditLocation } from '../models/Locations/audit-location';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(private httpClient: HttpClient ) { }
    httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
      responseType: 'json' as const,
      withCredentials: true,
    };
        getTasks(auditID:number,locationID:number): Observable<TasksResult>{
          return this.httpClient.post<TasksResult>(`${environment.apiUrl}tasks/gettasks`,{"AuditID":auditID,"LocationID":locationID}, this.httpOptions).pipe(
            map(res => {
              return res.body!;
            })
          );
        }

        getTaskLocations(): Observable<AuditLocation[]>{
          return this.httpClient.get<AuditLocation[]>(`${environment.apiUrl}locations/gettasklocations`, this.httpOptions).pipe(
            map(res => {
              return res.body!;
            })
          );
        }
        getTaskAudits(): Observable<BasicAuditResult>{
          return this.httpClient.get<BasicAuditResult>(`${environment.apiUrl}audits/gettaskauditlist`, this.httpOptions).pipe(
            map(res => {
              return res.body!;
            })
          );
        }
}
