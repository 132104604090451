import { DateTimeAdapter } from "@danielmoncada/angular-datetime-picker";
import { DDL } from "../ddl";
import { AuditLocation } from "../Locations/audit-location";

export class TaskInfo{
    public TaskID:number = -1;
    public AuditID:number = -1;
    public AuditName:string = "";
    public LastCompleted:Date;
    public PeriodStart:Date;
    public PeriodEnd:Date;
    public CompletedInPeriod:boolean;
    public Frequency:DDL;
    public FrequencyMultiplier:number = 1;
    public Location:AuditLocation;
    public TotalCompletedInPeriod:number = 0;
    constructor(values: Object = {}){
        Object.assign(this, values);
    }
}