import { MissedAuditRowDetail } from "./missed-audit-row-detail";
import { RowInformation } from "./row-information";

export class MissedAuditRow {
    public Details: MissedAuditRowDetail[] = [];
    public Info: RowInformation = new RowInformation();
    //fe only
    public subRows: MissedAuditRow[]=[];
    public expectedTotalAudits:number = 0 ;
    public completedAudits:number = 0;
    public percAuditsCompleted:number = 0;
    constructor(values: Object = {}) {
        Object.assign(this, values);
        if (values.hasOwnProperty('Details')) {
            this.Details = [];
            ((values as MissedAuditRow)['Details'] as MissedAuditRowDetail[]).forEach(element => {
                this.Details.push(new MissedAuditRowDetail(element));
            });
        }
       
    }
}