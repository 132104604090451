<div class="container mx-auto p-2 md:p-8">
    <h1 class="p-4">Select Location</h1>
    <div *ngIf="noLocations" class="p-6">
        <h4>No available locations for this audit.</h4>
    </div>
    <div *ngIf="!noLocations">
        <div class="p-4">
            <div *ngIf="!topLevel" class="hover:cursor-pointer flex align-middle" (click)="resetLocations()">
                <span class="text-center material-symbols-outlined text-green-700 self-center mr-1 ">undo</span><span>Reset Locations</span>
            </div>
            <div *ngFor="let l of selectedLocations" class="flex w-full my-2">
                <span
                    class="p-2 md:p-4 flex-grow standardTextSize bg-offwhite-500 border-spacing-1.5 border-b-grey-500 border-s-2  flex justify-between align-middle"
                    [ngClass]="{'selected': l.Location.LocationID == selectedLocation.Location.LocationID,'hover:hover-for-select': l.Location.HasPermission,'bg-grey-500 hover:cursor-not-allowed':!l.Location.HasPermission}"
                    (click)="selectLocation(l)">{{l.Location.LocationName}}</span>
                <div *ngIf="l!.SubLocations.length > 0"
                    class=" bg-offwhite-500 "
                    (click)="chooseSublocation(l)">
                    <span class="standardTextSize text-offblack hover:hover-for-select border-grey-900 border-spacing-2 border-s h-full px-4 flex items-center">Sub Locations</span>
                </div>
            </div>
        </div>
        <div class="p-4" *ngIf="!isCurrentAudit()">
            <button class="btn-primary-full max-w-xl" (click)="startAudit()" [class.disabled]="!canStart"
                [disabled]="!canStart">
                <span class="standardTextSize" *ngIf="canStart">Next ></span>
                <span class="standardTextSize" *ngIf="!canStart">Choose Location</span>
            </button>
        </div>
        <div *ngIf="isCurrentAudit() && !areYouSure" class="p-4">
            <button class="btn-primary-full standardTextSize  max-w-xl" (click)="forceNewAudit()">Start New Audit</button>
            <div class="mt-7 mb-4 border-grey-900 border-b-[1px]">

            </div>
            <!--<p>Existing incomplete <strong>{{selectedAudit.AuditName}}</strong> audits for location <strong>{{selectedLocation.Location.LocationName}}</strong>.</p>-->
            <h5 class="font-bold">Existing Incomplete Audit For Location</h5>
            <div class="mt-3  lg:mx-2">
                <app-incomplete-panel [Audits]="incompleteAuditList"  (Refresh)="refreshAudits()"></app-incomplete-panel>
            </div>
            
            
            <!--<div class="flex-col flex">
                <button class="btn-primary-full  max-w-xl" (click)="jumpToAudit()">Continue Existing Audit ></button>
                <button class="btn-primary-full  max-w-xl mt-4 btn-warning" (click)="areReadyToClear()">Clear Existing Audit and Continue ></button>
                
            </div>  >      -->
        </div>
        <div *ngIf="areYouSure" class="p-4">
            <p>Confirm removal of partially completed <strong>{{selectedAudit.AuditName}}</strong> audit for location <strong>{{selectedLocation.Location.LocationName}}</strong></p>
            <div class="flex-col flex">
                <button class="btn-primary-full  max-w-xl mt-4 btn-warning" (click)="removeAudit()">Confirm removal and Continue ></button>
                <button class="btn-primary-full  max-w-xl mt-4 btn-inverse" (click)="cancelReadyToClear()">Cancel</button>
            </div>        
        </div>
    </div>
    
</div>