import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, of, tap } from 'rxjs';
import { FeaturesList } from 'src/app/core-module/models/Locations/features-list';
import { ProgramFeatures } from 'src/app/core-module/models/UserManagement/program-features';
import { AuthService } from 'src/app/core-module/services/auth.service';
import { LocalStorageService } from 'src/app/core-module/services/local-storage.service';
import { ReportingService } from 'src/app/core-module/services/reporting.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  @Input() public menuIsOpen: boolean = false;
  @Output() menuHasBeenClosed = new EventEmitter<void>();
  public isBluebird: boolean = false;

  public programFeatures:ProgramFeatures = new ProgramFeatures();
  public permissionsChecked:boolean = false;


  constructor(public authService: AuthService, public router: Router, private localStorage: LocalStorageService, private reportingService:ReportingService){}

  ngOnInit(){
    if (environment.blueBird){
      this.isBluebird = true;
    }
    this.getFeaturePermissions();
  }

  async signout(){
    this.authService.signout().pipe(
      tap(res => {
        this.localStorage.clearData(1);
        this.localStorage.clearData(3);
        this.localStorage.clearData(4);
        this.router.navigate(['login']);
      })
    ).subscribe()
   
  }

  getFeaturePermissions(){
    if(!this.permissionsChecked){
      this.authService.getFeaturePermissions().pipe(
        tap(res => {
          if (!res.IsError){
            this.programFeatures = res;
          }
          this.permissionsChecked = true;
        })
      ).subscribe();
    }
  }

  menuOpen(){
    this.menuIsOpen = true;
  }

  closeMenu(){
    this.menuIsOpen = false;
    this.menuHasBeenClosed.emit();
  }
  startAudit(){
    this.router.navigateByUrl('startaudit',{state: {"AuditID" : -1,"WardID":-1}})
    this.closeMenu();
  }

}
