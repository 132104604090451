import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { BasicResult } from 'src/app/core-module/models/basic-result';
import { DDL } from 'src/app/core-module/models/ddl';
import { AuditLocation } from 'src/app/core-module/models/Locations/audit-location';
import { LocationAudit } from 'src/app/core-module/models/UserManagement/location-audit';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { LocationManagementService } from 'src/app/core-module/services/location-management.service';
import { ReferenceService } from 'src/app/core-module/services/reference.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';

@Component({
  selector: 'app-assign-location-audits',
  templateUrl: './assign-location-audits.component.html',
  styleUrl: './assign-location-audits.component.scss'
})
export class AssignLocationAuditsComponent {
  constructor(private router: Router, private UMS: UserManagementService, private LMS: LocationManagementService, public toastrs:ToastrService, public RS: ReferenceService) {
    this.getLocations();
    RS.getFrequencies().pipe(
      tap(res => {
        this.frequencies = res;
      })
    ).subscribe();
  }

  public originalData: AuditLocation[] = [];
  public minLevelID: number;
  public minSeq: number;
  public possibleLocations: AuditLocation[] = [];
  public locationsReady: boolean = false;
  public topLevel: boolean = true;

  public isLocationSelected: boolean = false;
  public showLocation: boolean = true;

  public selectedLocation: AuditLocation = new AuditLocation();
  public loadedLocation: AuditLocation = new AuditLocation();

  public editingParent: boolean = false;
  public selectedParent: AuditLocation = new AuditLocation();
  public minParentLevelID: number = -1;
  public topParentLevel: boolean = false;

  public canStart: boolean = false;

  public isLoading:boolean = false;
  public isSaving:boolean = false;
  public noAudits:boolean = false;

  public showAudits: boolean = false;
  public audits: LocationAudit[] = [];
  public originalAudits: LocationAudit[] = [];

  public frequencies:DDL[] = [];

  public allPermissions: boolean = false;
  getLocations() {
    this.LMS.getAllLocationsToAssignAudit().pipe(tap(a => { this.originalData = a, this.setMinLevel(), this.initialList(); })).subscribe()
  }



  setMinLevel() {
    this.minSeq = this.originalData[0].LocationLevelSeq;
    this.minLevelID = this.originalData[0].LocationLevelID;
    this.originalData.forEach(e => {
      if (e.LocationLevelSeq < this.minSeq) {
        this.minSeq = e.LocationLevelSeq;
        this.minLevelID = e.LocationLevelID;
      }
    })
  }
  initialList() {
    this.possibleLocations = [];
    this.topLevel = true;

    //this.possibleLocations.push(new UserLocation({HasPermission:false,LocationName:"Please select...",LocationID:-1}))
    //this.selectedLocation = this.possibleLocations[0];
    this.originalData.forEach(e => {
      if (e.LocationLevelID == this.minLevelID) {
        this.possibleLocations.push(new AuditLocation(e));
      }
      this.locationsReady = true;
    })
    
  }
  confirmLocation() {
    //fetch audits for location
    this.isLoading = true;
    this.UMS.getLocationAudits(this.selectedLocation.LocationID).pipe(tap((a) => { this.constructAudits(a);this.isLoading = false; })).subscribe();

    this.showAudits = true;
  }
  constructAudits(audits:LocationAudit[]){
    this.originalAudits = audits;
    this.originalAudits.sort((a,b)=>a.AuditName.localeCompare(b.AuditName));
    this.setEditableAudits();
  }
  setEditableAudits(){
    this.audits = [];
    this.originalAudits.forEach(e=>{
      this.audits.push(new LocationAudit(e));
    });
    this.allPermissionCheck();
    console.log(this.audits);
  }
  undoChanges(){
    this.setEditableAudits();
  }
  selectLocation(location: AuditLocation) {

    if (location.HasPermission) {
      this.selectedLocation = location;
      this.isLocationSelected = true;
    }

    //this.loadedLocation = this.selectedLocation;

    //this.resetParentLocation();
  }

  /*resetParentLocation() {
    this.editingParent = false;
    this.selectedParent = new AuditLocation();
    this.minParentLevelID = -1;
    this.topParentLevel = false;
  }*/
  resetLocations() { this.initialList(); this.isLocationSelected = false; this.selectedLocation = new AuditLocation(),this.isSaving = false; };
  locationHasChildren(location: AuditLocation) {
    return this.originalData.findIndex(x => x.ParentLocationID == location.LocationID) != -1;
  };

  buildLocationDDL(locationID: number) {
    this.topLevel = false;
    var auditLocations: AuditLocation[] = [];
    this.originalData.forEach(e => {
      if (e.ParentLocationID == locationID) {
        auditLocations.push(e);
      }
    });
    this.possibleLocations = auditLocations;
  }


  updateAllPermissions() { 
    this.audits.forEach(e=>{
      e.AssignedToLocation = this.allPermissions;
    })
  }
  alterPermission(){
    this.allPermissionCheck();
  }
  allPermissionCheck(){
    var all = true;
    this.audits.forEach(e=>{
      if(e.AssignedToLocation == false){
        all = false;
      }
    })
    this.allPermissions = all;
  }
  resetPage(){
    this.audits = [];
    this.selectedLocation = new AuditLocation();
    this.resetLocations();
    this.isLoading = false;
    this.showAudits = false;
    this.isSaving = false;
  }
  saveChanges(){
     //validate changes
     this.isSaving = true;
    if(this.hasChanged()){
      this.UMS.saveLocationAudits(this.audits).pipe(tap((a) => { this.handleSaveReturn(a)})).subscribe();
     
    }else{
      this.toastrs.addToastr(new Toastr({Message: 'No changes detected.', Type: 1}));
      this.isSaving = false;
    }

  }
  handleSaveReturn(a:BasicResult){
    if(a.IsError == false){
      this.commitSavedAudit();
      this.toastrs.addToastr(new Toastr({Message: 'Location Audits Saved.', Type: 2}));
    }else{
      this.toastrs.addToastr(new Toastr({Message: 'Unable to save at this time.', Type: 4}));
      this.isSaving = false;
    }
  
  }
  commitSavedAudit(){
    this.originalAudits = this.audits;
    this.setEditableAudits();
    this.isSaving = false;
  }
  hasChanged(){
    var changed:boolean = false;
    this.originalAudits.forEach(e=>{
      if(this.audits.find(f=>f.AuditID == e.AuditID)!.AssignedToLocation != e.AssignedToLocation 
            || this.audits.find(f=>f.AuditID == e.AuditID)!.Frequency.ID != e.Frequency.ID 
            || this.audits.find(f=>f.AuditID == e.AuditID)!.FrequencyMultiplier != e.FrequencyMultiplier){
        changed = true;
      }

    });
    return changed;
  }
  goBack() {
    this.router.navigateByUrl("/settings");
  }

  editFrequency(a:LocationAudit, show:boolean){
    a.ShowFrequencyUpdate = show
  }
}
