import { BasicResult } from "../basic-result";
import { TaskInfo } from "./TaskInfo";

export class TasksResult extends BasicResult{
    public Tasks:TaskInfo[]=[];


    constructor(values: Object = {}){
        super();        
        Object.assign(this, values);
        if (values.hasOwnProperty('Tasks')) {
            this.Tasks = [];
            ((values as TasksResult)['Tasks'] as TaskInfo[]).forEach(element => {
                this.Tasks.push(new TaskInfo(element));
            });
        }
    }
}