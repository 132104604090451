import { Component, Input } from '@angular/core';
import { UtilService } from 'src/app/core-module/services/util.service';

@Component({
  selector: 'app-audit-main-header',
  templateUrl: './audit-main-header.component.html',
  styleUrls: ['./audit-main-header.component.scss']
})
export class AuditMainHeaderComponent {
  @Input() showHeader:boolean;  
  @Input() header:string = "";
  @Input() locationName:string;
  @Input() auditName:string;
  constructor(private util:UtilService){

  }
  shortenedHeader():string{
    if(this.showHeader){
      return this.util.limitCharacters(this.header,100)
    }else{
      return "";
    }
   
  }
}
