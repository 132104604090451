<!--<button (click)="test()">Test</button>-->
<!--<div class="border-t-4 border-green-700 border-b-4 "></div>-->
<div class="flex mt-3  lg:hidden mx-5">
    <button class="btn-inverse py-1" (click)="goBack()">
        < Back</button>
</div>
<p class="underline hover:cursor-pointer ml-3 py-2 hidden lg:block" (click)="goBack()">
    < Back</p>

        <div class=" px-10 py-5 mt-3 mx-5  border-green-400 border-4 rounded-lg bg-grey-100"
            [ngClass]="{'border-grey-900':action.Resolved, 'border-red-600 ': hasDeadlinePassed(action.ActionDeadline) && !action.Resolved, 'border-warning-amber':isDeadlineDue(action.ActionDeadline)  && !action.Resolved}">
            <div class="flex justify-between flex-col lg:flex-row">
                <p class="">Assign To: <span class="text-lg font-bold">{{action.ActionAssignee}}</span></p>
                <p class="">Due: <span class="text-lg font-bold">{{getDateText(action.ActionDeadline)}}</span></p>
            </div>
            <p class="">Status: <span *ngIf="action.Resolved" class="text-lg font-bold">Closed</span><span
                    *ngIf="!action.Resolved" class="text-lg font-bold">Open</span></p>

        </div>

        <div class="mx-5 mt-5">
            <!--<div class="flex justify-end pr-2">
        <button class="btn-inverse py-1" (click)="goBack()"> < Back</button>
    </div>-->
            <!--<p class="underline hover:cursor-pointer ml-3 py-1" (click)="goBack()">< Back</p>-->

            <div class="lg:mx-5 mx-2">
                <div class="flex">
                    <h3 class="mr-4">Audit Details</h3><span (click)="changeAuditDetailView()" *ngIf="!showAuditDetails"
                        class="material-symbols-outlined text-3xl align-bottom  self-center text-green-300 cursor-pointer">expand_circle_down</span><span
                        (click)="changeAuditDetailView()" *ngIf="showAuditDetails"
                        class="material-symbols-outlined text-3xl align-bottom  self-center text-green-300 cursor-pointer">expand_circle_up</span>
                </div>
                <div *ngIf="showAuditDetails">
                    <p class="mt-3"><strong>{{action.AuditText}}</strong> ({{action.AuditLocation.LocationName}}) </p>
                    <div class="flex">
                        <p class="text-lg text-green-300">Q</p>
                        <div class="mt-1 ml-2">
                            <p>{{action.QuestionHeader}}</p>
                            <p>{{action.QuestionBody}}</p>
                        </div>
                        <p></p>
                    </div>
                    <div class="flex">
                        <p class="text-lg ">A</p>
                        <p class="mt-1 ml-2">{{action.Result}}</p>
                    </div>
                    <p class="italic text-grey-900 mt-1 ml-6">{{action.Comments}} <span class="text-grey-500"
                            *ngIf="!actionComments()"> No comments</span></p>
                </div>
                <div *ngIf="action.Reference.length>0">
                    <div class="border-solid border-[1px] border-grey-100 mt-2"></div>
                    <p class="underline text-lg mt-3">Reference</p>
                    <div class="flex mt-1">
                        <p class="text-lg text-green-300">Q</p>
                        <div class="mt-1 ml-2">
                            <p>{{action.ReferenceQuestionHeader}}</p>
                            <p>{{action.ReferenceQuestionBody}}</p>
                        </div>
                    </div>
                    <div class="flex">
                        <p class="text-lg ">A</p>
                        <p class="mt-1 ml-2">{{action.Reference}}</p>
                    </div>
                    <div class="border-solid border-[1px] border-grey-100 mt-2"></div>
                </div>
                <div class="flex mt-3">
                    <span
                        [ngClass]="{'text-green-400 cursor-pointer':action.Attachments.length>0,'text-grey-500 cursor-not-allowed':action.Attachments.length<=0}"
                        class="material-symbols-outlined text-3xl align-bottom  self-center"
                        (click)="switchAttachmentPanel()">attach_file</span>
                    <span
                        [ngClass]="{'text-green-400 cursor-pointer':action.Photos.length>0,'text-grey-500 cursor-not-allowed':action.Photos.length<=0}"
                        class="material-symbols-outlined text-3xl align-bottom  self-center ml-2"
                        (click)="switchPhotoPanel()">photo_camera</span>
                </div>
                <div *ngIf="expandedPhotoPanel"
                    class="px-2 mx-5 border-[1px] border-solid border-grey-900 mb-2 rounded-md">
                    <h4 class="mb-4 mt-2 font-semibold text-green-700">Photos</h4>
                    <div class="flex flex-wrap">
                        <div *ngFor="let p of action.Photos" class=" ml-2 mb-2 ">
                            <img [src]="photoSrc(p)" class="max-h-52" />
                        </div>
                    </div>

                </div>
                <div *ngIf="expandedAttachmentPanel"
                    class="p-2 mx-5 border-[1px] border-solid border-grey-900 mb-2 rounded-md">
                    <h4 class="mb-4 font-semibold text-green-700">Attachments</h4>

                    <div *ngFor="let q of action.Attachments" class="mt-1">
                        <p class="underline hover:text-green-700 cursor-pointer" (click)="downloadAttachment(q.GUID)">
                            {{filenameReduction(q.FileName)}}</p>
                    </div>

                </div>
            </div>
            <div class="border-solid border-2 border-grey-500 my-5"></div>

            <div class="lg:mx-5 mx-2 flex lg:flex-row flex-col">
                <div class="lg:w-3/5 w-full">
                    <div class="flex items-baseline">
                       <h3>Action Details</h3> 
                       <div class="ml-2">
                        <p class=" hover:cursor-pointer" (click)="editActionSwitch()"><span class="text-center material-symbols-outlined text-xl text-green-700 self-center mr-1 align-sub">edit</span> <span class="underline">edit action</span></p>
                       </div>
                    </div>
                    
                    <div class="mt-3">
                        <p>Action Created: <strong>{{action.ActionCreator}}</strong>
                            ({{getDateText(action.DateRecorded)}})</p>
                    </div>
                    <div *ngIf="editAction" class="border-solid border-[1px] border-grey-500  mr-5 my-3"></div>
                    <div [ngClass]="{'mt-2':!editAction, 'mt-5 lg:mr-5':editAction}">
                        <p for="ActionDescription">Action Description:</p>
                        <p *ngIf="!editAction" class="font-bold">{{action.ActionDescription}}</p>
                        <textarea *ngIf="editAction" #actionDescription [(ngModel)]="action.ActionDescription"
                            class="text-box lg:mx-2"></textarea>
                        <p *ngIf="noDescriptionWarning && editAction" class="text-red-600 lg:ml-2 mt-1">Please enter a
                            description prior to saving.</p>
                        <div class="mt-2">
                            <!--<p *ngIf="!editAction"><strong>Assigned To:</strong> {{action.ActionAssignee}}<span
                            *ngIf="!CurrentActionUser.Valid">*</span></p>-->
                            <div [ngClass]="{'hidden':!editAction}">
                                <strong>Action Assigned To:</strong>
                                <select class="text-box lg:w-2/5 lg:mx-2" name="ActionUsers"
                                    [(ngModel)]="CurrentActionUser" [compareWith]="userComparison">
                                    <option *ngFor="let a of _users" [ngValue]="a" [disabled]="a.UserID == -1"
                                        [ngClass]="{'font-bold':a.UserTypeID==2}">{{a.DisplayName}}<span
                                            *ngIf="!a.Valid">*</span>
                                    </option>
                                </select>

                            </div>
                            <p *ngIf="!CurrentActionUser.Valid" class="mt-2">*The currently assigned user does not have
                                the
                                correct
                                permissions
                                for this action.</p>
                        </div>
                        <div class="mt-2">
                            <!--<p *ngIf="!editAction" class=""><strong>Action Due:</strong> {{getDateText(action.ActionDeadline)}}
                    </p>-->
                            <div class="" *ngIf="editAction">
                                <label>
                                    <strong>Action Due Date:</strong>
                                    <input [owlDateTime]="dt" class="text-box lg:w-2/5 lg:mx-2"
                                        [(ngModel)]="action.ActionDeadline" [owlDateTimeTrigger]="dt" />
                                    <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
                                </label>
                            </div>
                        </div>
                        <div *ngIf="action.Resolved && !editAction && action.ResolvedUser.length>0" class="mt-2">
                            <p><strong>Completed By:</strong> {{action.ResolvedUser}}
                                ({{getDateText(action.ResolvedDate)}})</p>
                        </div>
                    </div>

                    <div class="mt-2 lg:mr-5" *ngIf="!editAction">
                        <div class="flex justify-start my-3 items-center">
                            <strong>Closed:</strong><app-toggle-switch [(checked)]="action.Resolved"
                                (checkedChange)="updateCompletedBy()" class="ml-2"></app-toggle-switch>
                        </div>
                        <div>
                            <textarea rows="3" placeholder="Notes" [(ngModel)]="action.NewNote"></textarea>
                        </div>
                    </div>
                    <div class="flex mt-3">
                        <button *ngIf="!editAction" class="btn btn-primary mr-2"
                            (click)="validateActionUpdate()">Save</button>
                        <div [ngClass]="{'mb-5 mt-2':editAction}"
                            class="flex  lg:block">
                            <!--<button *ngIf="!editAction" class="btn btn-primary "
                                (click)="editActionSwitch()">Edit</button>-->
                                <button *ngIf="editAction" class="btn btn-primary mr-2"
                                (click)="validateAction()">Save</button>
                            <button *ngIf="editAction" class="btn btn-primary  lg:mr-2 btn-inverse"
                                (click)="undoChanges()">Cancel</button>
                       
                        </div>
                    </div>


                </div>
                <div class="lg:p-2 pt-2 flex flex-grow lg:w-2/5 w-full">
                    <div
                        class="rounded-lg border-solid border-2 border-grey-100 p-2 flex  flex-col w-full max-h-80 overflow-y-auto">
                        <h3 class="mb-2">Action History</h3>
                        <div *ngIf="ActionHistory.length==0">
                            <p class=" ml-2 text-grey-300">No Action History</p>
                        </div>
                        <div *ngFor="let ah of ActionHistory" class="flex flex-col mb-2 ">
                            <p class="font-semibold text-green-400 mb-1">{{ah.ChangeDescription}}</p>

                            <p><span class="font-semibold mb-1 mr-1">Date:</span>{{util.humanDate(ah.DateOfChange)}}</p>
                            <p *ngIf="ah.PreviousValue.length>0"><span class="font-semibold mb-1 mr-1">Previous:</span>{{ah.PreviousValue}}</p>
                            <p *ngIf="ah.CurrentValue.length>0"><span class="font-semibold mr-1">New Value:</span>{{ah.CurrentValue}}</p>
                            <p *ngIf="ah.Notes.length>0"><span class="font-semibold mr-1">Notes:</span>{{ah.Notes}}</p>

                            <div class="w-10/12 border-b-[1px] border-grey-300 border-solid"></div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="border-solid border-2 border-grey-500 my-5 "></div>
            <div class="lg:mx-5 mx-2">
                <div class="flex" *ngIf="!newActionCreator">
                    <button class="btn btn-primary lg:mr-2 lg:w-2/5 w-full" (click)="updateNewAction()">New
                        Action</button>
                </div>
                <app-new-action-quick *ngIf="newActionCreator" (saveNewAction)="saveNewAction()"
                    [(newAction)]="newQuickAction" (resetNewAction)="updateNewAction()"
                    [Users]="_users"></app-new-action-quick>

            </div>

            <div class="border-solid border-2 border-grey-500 my-5 "></div>
            <!--<div class="mx-5">
        <h3>Action Updates</h3>
        <div *ngFor="let r of action.ActionResponseList">
            <p>{{getDateText(r.ResponseDate)}}: {{r.Username}} - {{r.Note}} - Resolved: {{r.Resolved}}</p>
        </div>
        <p>History of changes</p>
    </div>-->


        </div>