<div class="container p-4">
    <div class="px-4 mt-2">
        <button class="btn-inverse py-1" (click)="goBack()">
            < Back</button>
    </div>

    <div class="border-solid border-2 border-grey-500 mb-1 mt-3 mx-4"></div>
    <h1 class="p-4">Location Audits</h1>
    <div class="mx-5" *ngIf="!showAudits">
        <h3 class="mb-5 mt-3">Select Location</h3>
        <div class="" *ngIf="showLocation">
            <div *ngIf="!topLevel" (click)="resetLocations()" class="flex">
                <span
                    class="text-center material-symbols-outlined text-green-700 self-center mr-1 ">undo</span><span class="hover:underline hover:cursor-pointer">Reset
                    Locations</span>
            </div>
            <div *ngIf="locationsReady">
                <div *ngFor="let l of possibleLocations" class="flex w-full my-2">
                    <span
                        class="p-2 md:p-4 flex-grow standardTextSize border-spacing-1.5 border-b-grey-500 border-s-2 flex  align-middle hover:hover-for-select"
                        [ngClass]="{'selected': l.LocationID == selectedLocation.LocationID, 'hover:hover-for-select  ':l.HasPermission, 'bg-grey-100 hover:cursor-not-allowed':!l.HasPermission,' bg-grey-300':l.HasPermission&&l.LocationID!=selectedLocation.LocationID}"
                        (click)="selectLocation(l)">{{l.LocationName}}<span *ngIf="!l.Active"
                            class="ml-2 text-red-600 font-bold">(Inactive)</span></span>
                    <div *ngIf="locationHasChildren(l)" class=" bg-standardwhite "
                        (click)="buildLocationDDL(l.LocationID)">
                        <span
                            class="standardTextSize text-offblack hover:hover-for-select bg-grey-300 border-grey-900 border-spacing-2 border-s h-full px-4 flex items-center">Sub
                            Locations</span>
                    </div>
                </div>
                <div class="mt-5" *ngIf="true">
                    <button class="btn-primary-full max-w-xl" (click)="confirmLocation()" [class.disabled]="!canStart"
                        [disabled]="!isLocationSelected">
                        <span class="standardTextSize" *ngIf="isLocationSelected">Next ></span>
                        <span class="standardTextSize" *ngIf="!isLocationSelected">Choose Location</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isLoading" class="mx-5">
        <h2>Loading...</h2>
    </div>
    <div *ngIf="showAudits && !isLoading" class="mx-5">
        <div class="my-3">
            <h3>{{this.selectedLocation.LocationName}}</h3>
        </div>
        <div class="flex align-middle" (click)="resetPage()"><span
            class="text-center material-symbols-outlined text-green-700 self-center mr-1 ">undo</span><span class=" hover:underline hover:cursor-pointer">Change
            Location</span>
           
        </div>
        <table>
            <tbody>
                <tr class="border-b-offblack border-b-2 mb-2 py-1">
                    <td class="font-bold">All</td>
                    <td></td>
                    <td class="flex justify-center py-2 pl-3 mr-2">
                        <app-toggle-switch [(checked)]="allPermissions" (checkedChange)="updateAllPermissions()"></app-toggle-switch>
                    </td>
                    <td class="border-l-2 border-offblack p-2 ">Frequency</td>
                </tr>

                <ng-container *ngFor="let a of audits">
                    <tr>
                        <td>{{a.AuditName}}</td>
                        <td class="px-1">{{a.AuditType}}</td>
                        <td class="flex justify-center py-2 pl-3 mr-2">
                            <app-toggle-switch [(checked)]="a.AssignedToLocation" (checkedChange)="alterPermission()"></app-toggle-switch>
                        </td>
                        <td class="border-l-2 border-offblack p-2">
                            <div class="flex hover:cursor-pointer hover:bg-green-50 hover:rounded-md p-2" (click)="editFrequency(a,true)">
                                <span class="text-center material-symbols-outlined self-center mr-1 text-sm">edit</span>
                                <p class="pl-1 text-sm"><span *ngIf="a.FrequencyMultiplier > 1">{{a.FrequencyMultiplier}} x </span>{{a.Frequency.Description}}</p>
                            </div>
                            
                        </td>
                    </tr>
                    <tr *ngIf="a.ShowFrequencyUpdate">
                        <td colspan="4" class="border border-grey-500 p-2">
                            <div class="flex justify-between">
                                <h4 class="font-bold">Update Frequency</h4>
                                <span class="text-center material-symbols-outlined self-center mr-1 text-sm hover:cursor-pointer hover:bg-green-50 hover:rounded-full w-5 h-5" (click)="editFrequency(a,false)">close</span>
                            </div>

                            <div class="grid grid-cols-2 mt-4 gap-2 items-center">
                                <p>Number of times audit is required to be done in period:</p>
                                <div class="pl-2">
                                    <app-number-box-entry [(value)]="a.FrequencyMultiplier"></app-number-box-entry>
                                </div>
                                <p>Completion frequency:</p>
                                <div class="pl-2">
                                    <app-dropdown-entry [(value)]="a.Frequency" [options]="frequencies"></app-dropdown-entry>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
                <tr class="border-b-grey-500 border-b-[1px] mt-2 py-2"></tr>
            </tbody>
        </table>
        <div class="mt-4">
            <button class="btn-primary mr-4" (click)="saveChanges()" [disabled]="isSaving">
                <span *ngIf="!isSaving">Save</span>
                <span *ngIf="isSaving">Saving...</span>
            </button>
            <button class="btn-warning" (click)="undoChanges()" [disabled]="isSaving">Undo</button>
        </div>
    </div>
</div>