import { FlattenedQuestionHolder } from "./Audits/flattened-question-holder";
import { AuditLocation } from "./Locations/audit-location";

export class UserLocationSave{
    Username: string = "";
    Location: AuditLocation;
    AuditGUID: string = "";
    FlattenedQuestions:FlattenedQuestionHolder[] = [];
    constructor(values: Object = {}) {
        Object.assign(this, values);
        if (values.hasOwnProperty('FlattenedQuestions')) {
            this.FlattenedQuestions = [];
            ((values as UserLocationSave)['FlattenedQuestions'] as FlattenedQuestionHolder[]).forEach(element => {
                this.FlattenedQuestions.push(new FlattenedQuestionHolder(element));
            });
        }
        if (values.hasOwnProperty('Location')) {
            this.Location = new AuditLocation((values as UserLocationSave)['Location'] as AuditLocation);
        }
    }
}