import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { SaveUser } from 'src/app/core-module/models/UserManagement/save-user';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { ReferenceService } from 'src/app/core-module/services/reference.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';
import { UtilService } from 'src/app/core-module/services/util.service';

@Component({
  selector: 'app-current-users',
  templateUrl: './current-users.component.html',
  styleUrl: './current-users.component.scss'
})
export class CurrentUsersComponent {

  private originalList: SaveUser[] = [];
  public mobileList: SaveUser[] = [];
  public isGroup: boolean = false;
  public saving: boolean = false;


  public editing: boolean = false;
  public currentUser: SaveUser = new SaveUser();
  public currentUserOrig: SaveUser;

  public invalidUserName: boolean = false;
  public isUsernameInUse: boolean = false;
  public invalidDisplayName: boolean = false;
  public invalidEmail: boolean = false;
  public emailUsed: boolean = false;

  constructor(private UMS: UserManagementService, private util: UtilService, private ref: ReferenceService, public router: Router, private toast: ToastrService) {
    UMS.getManageUsers(this.isGroup).pipe(tap((a) => { this.originalList = a; this.constructCurrentUsers() })).subscribe();
  }
  constructCurrentUsers() {
    if (this.originalList.length > 0) {
      this.originalList.sort((a, b) => a.DisplayName.localeCompare(b.DisplayName))
      this.mobileList.push(new SaveUser({ "DisplayName": "Please select", "UserID": -1, "Disabled": true }))
      this.originalList.forEach(x => {
        this.mobileList.push(new SaveUser(x));
        if (this.currentUser.UserID == -1) {
          this.currentUser = this.mobileList[0];
        }
      })

    }

  }
  selectUser(user: SaveUser) {
    this.resetFlags();
    this.currentUserOrig = new SaveUser(user);
    this.currentUser = user;
    this.editing = true;
  }
  resetFlags() {
    this.invalidUserName = false;
    this.invalidEmail = false;
    this.invalidDisplayName = false;
  }

  switchEditing() {
    this.editing = !this.editing;
    this.currentUser = this.mobileList[0];
    for (var i = 0; i < this.mobileList.length; i++) {
      if (this.mobileList[i].UserID == this.currentUserOrig.UserID) {
        this.mobileList[i] = this.currentUserOrig;
      }
    }
  }

  selectMobileUser() {
    this.resetFlags();
    this.currentUserOrig = new SaveUser(this.currentUser);
    this.editing = true;
  }
  validate() {
    this.saving = true;
    this.resetFlags();
    var isValid = true;
    if (!this.isGroup) {
      if (this.currentUser.Username.length < this.ref.minUsernameLength) {
        this.invalidUserName = true;
        isValid = false;
      }
      if (this.util.hasWhiteSpace(this.currentUser.Username)) {
        this.invalidUserName = true;
        isValid = false;
      }
      if (!this.util.validEmail(this.currentUser.Email)) {
        this.invalidEmail = true;
        isValid = false;
      }
    }
    if (this.currentUser.DisplayName.length < this.ref.minUsernameLength) {
      this.invalidDisplayName = true;
      isValid = false;
    }
    if (isValid) {
      this.saveUser();
    } else {
      this.saving = false;
    }
  }

  saveUser() {
    if (this.isGroup) {
      this.currentUser.UserTypeID = 2;
    } else {
      this.currentUser.UserTypeID = 1;
    }
    this.UMS.saveManageUsers(this.currentUser).pipe(
      tap((a) => {
        if (!a.IsError) {
          this.currentUserOrig = new SaveUser(this.currentUser);
          this.toast.addToastr(new Toastr({ Type: 2, Message: "Profile Saved." }));
        } else {
          switch (a.ErrorCode) {
            case "SMU002":
              this.toast.addToastr(new Toastr({ Type: 3, Message: "No changes have been made." }));
              break;
            case "SMU003":
              this.toast.addToastr(new Toastr({ Type: 3, Message: "Users can only be deactivated once they have logged in." }));
              break;
            default:
              this.toast.addToastr(new Toastr({ Type: 3, Message: "An error occurred saving the profile, please try again later." }));
          }
        }
        this.saving = false;
      })
    ).subscribe();
  }
  goBack() {
    this.router.navigateByUrl("/settings");
  }

}
