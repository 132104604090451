<div *ngIf="!pageReady">
  <p>Loading...</p>
</div>

<div class="flex h-[calc(100vh-3rem-env(safe-area-inset-top))] lg:h-[100vh] justify-between">
  <div class="flex flex-col justify-between pb-5 px-4 md:pr-0 grow w-[calc(100%-6rem)]"
    [ngClass]="{ 'hidden lg:flex ': showSidePanel }">
    <app-audit-main-header *ngIf="showHeadingSection() && pageReady &&!showSummaryInformation"
      [auditName]="currentData.AuditName" [header]="currentHeader" [locationName]="location.LocationName"
      [showHeader]="showHeader()"></app-audit-main-header>

    <div *ngIf="pageReady && !showSummaryInformation"
      class="flex-grow flex overflow-y-auto shadow-[0px_9px_13px_0px_rgba(0,0,0,0.1)] mt-5 rounded-xl">

      <div class="flex-grow bg-offwhite-100  w-3/4 px-4 py-2 flex justify-between flex-col h-fit min-h-full">
        <app-question-holder [(currentQuestion)]="currentQuestion" (updateLocal)="triggerLocalSave()" class="flex-grow "
          [actionUsers]="actionUsers"></app-question-holder>

      </div>
    </div>
    <div class="lg:ml-4 w-full my-5 mb-4" *ngIf="pageReady && !showSummaryInformation">
      <div *ngIf="currentQuestion.IsRepeatEnd"
        class="flex flex-col align-middle py-2 my-2 border-y-[1px] border-grey-500">

        <div class="flex flex-col justify-start md:flex-row md:justify-between mb-3">
          <h4 class="mb2 md:mb-0">Repeatable Section</h4>
          <p>Repeat section begins at
            <strong>{{getRepeatQuestion(this.currentQuestion.RepeatStartID)}}</strong>
          </p>

        </div>
        <button (click)="repeatSection()" class="btn-inverse lg:w-1/2 w-full self-start">
          Create Repeat Section
        </button>
      </div>
      <div *ngIf="currentQuestion.IsRepeatStart"
        class="flex flex-col align-middle py-2 my-2 border-y-[1px] border-grey-500">

        <div class="flex flex-col justify-start md:flex-row md:justify-between mb-3">
          <h4 class="mb2 md:mb-0">Repeatable Section</h4>
          <p>Repeat section ends at 
            <strong>{{getRepeatQuestion(this.currentQuestion.RepeatEndID)}}</strong>
          </p>

        </div>
        <button (click)="clearRepeat()" *ngIf="canClear()" class="btn-inverse lg:w-1/2 w-full self-start">
          Remove Current Repeat Section
        </button>
      </div>
      <div class="mr-2 flex justify-between lg:w-3/4 w-full mt-4">
        <button (click)="prevQuestion()" class="btn-primary mr-2 w-1/2" [disabled]="this.prevGUID == '-1'">
          Previous
        </button>

        <button (click)="nextQuestion()" class="btn-primary  w-1/2" *ngIf="this.nextGUID != '-1'">
          Next
        </button>
        <button (click)="goToSummary()" class="btn-primary  w-1/2" *ngIf="this.nextGUID == '-1'">
          Complete
        </button>
      </div>
      <button class="btn-primary px-0 hidden md:hidden mt-3 w-full" (click)="switchShowSidePanel()">Overview</button>
    </div>
    <div *ngIf="showSummaryInformation"
      class="flex flex-col mx-2 p-2 mt-4 lg:mx-4 lg:mt-8 lg:p-4 border-grey-300 rounded-md border-2  items-center">
      <h3>Audit Summary</h3>
      <div class="mt-8 flex flex-col lg:hidden">
        <h4>Audit</h4>
        <h4 class="font-bold mx-2">{{currentData.AuditName}}</h4>
        <div class="border-b-[1px] border-grey-100 mb-1"></div>
        <h4>Location</h4>
        <h4 class="font-bold mx-2">{{location.LocationName}}</h4>
        <div class="border-b-[1px] border-grey-100 my-1"></div>
        <h4>Unanswered Questions</h4>
        <h4 class="font-bold ml-2">{{missingQuestions}}</h4>
        <div class="border-b-[1px] border-grey-100 my-1"></div>
        <h4>Missing Required</h4>
        <h4 class="font-bold ml-2">{{missingRequiredQuestions}}</h4>
        <div class="border-b-[1px] border-grey-100 my-1"></div>
        <h4>Missing Actions</h4>
        <h4 class="font-bold ml-2">{{missingActions}}</h4>
        <div class="border-b-[1px] border-grey-100 my-1"></div>
        <h4>Total Actions Raised</h4>
        <h4 class="font-bold ml-2">{{actionCounter}}</h4>
        <div class="border-b-[1px] border-grey-100 my-1"></div>
        <h4>Score</h4>
        <div class="flex  ml-2">
          <h4 class="font-bold mr-2 ">{{percentageScore}}%</h4>
          <h4>({{totalScore}}/{{possibleScore}})</h4>
        </div>
        <div class="border-b-[1px] border-grey-100 my-1"></div>
      </div>
      <table class="mt-8 hidden lg:block">
        <tbody>
          <tr>
            <td class="lg:pr-2 pr-1">
              <h4>Audit</h4>
            </td>
            <td>
              <h4 class="font-bold">{{currentData.AuditName}}</h4>
            </td>
          </tr>
          <tr>
            <td class="lg:pr-2 pr-1">
              <h4>Location</h4>
            </td>
            <td>
              <h4 class="font-bold">{{location.LocationName}}</h4>
            </td>
          </tr>
          <tr>
            <td class="lg:pr-2 pr-1">
              <h4>Unanswered Questions</h4>
            </td>
            <td>
              <h4 class="font-bold">{{missingQuestions}}</h4>
            </td>
          </tr>
          <tr>
            <td class="lg:pr-2 pr-1">
              <h4>Missing Required</h4>
            </td>
            <td>
              <h4 class="font-bold">{{missingRequiredQuestions}}</h4>
            </td>
          </tr>
          <tr>
            <td class="lg:pr-2 pr-1">
              <h4>Missing Actions</h4>
            </td>
            <td>
              <h4 class="font-bold">{{missingActions}}</h4>
            </td>
          </tr>
          <tr>
            <td class="lg:pr-2 pr-1">
              <h4>Total Actions Raised</h4>
            </td>
            <td>
              <h4 class="font-bold">{{actionCounter}}</h4>
            </td>
          </tr>
          <tr>
            <td class="lg:pr-2 pr-1">
              <h4>Score</h4>
            </td>
            <td>           
                <div *ngIf="possibleScore == 0">N/A</div>
                <div *ngIf="possibleScore > 0">
                     <div class="flex">
                      <h4 class="font-bold mr-2">{{percentageScore}}%</h4>
                      <h4>({{totalScore}}/{{possibleScore}})</h4>
                     </div>
                </div>          
            </td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="missingRequiredQuestions==0 && missingActions == 0" class="mt-8 lg:w-3/4 w-10/12 ">
        <button class="btn-primary lg:text-2xl text-lg w-full" [disabled]="submitting" (click)="validateAudit()">
          <span *ngIf="!submitting" class="lg:text-2xl text-lg">Submit</span><span *ngIf="submitting"
            class="lg:text-2xl text-lg">Submitting</span>
        </button>

      </div>
      <div *ngIf="missingRequiredQuestions==0 && missingActions == 0" class="flex justify-center mt-3">
        <p class="underline hover:cursor-pointer " (click)="returnFromSummary()">Return to Questions</p>
      </div>
      <div class="mt-8 w-full border-y-2 border-grey-300 flex justify-center"
        *ngIf="missingRequiredQuestions>0 || missingActions>0">
        <p class=" my-2 mx-2 text-red-600 font-bold">Please complete all required questions and actions before
          submitting the audit.</p>
      </div>
      <div *ngIf="missingRequiredQuestions>0 || missingActions > 0" class="mt-4 lg:mt-8 lg:w-3/4 w-10/12 ">
        <button class="btn-primary w-full" (click)="returnFromSummary()">
          Return to Questions
        </button>
      </div>
    </div>
    <div *ngIf="hasMissingQuestions" class="flex justify-center">
      <div class="flex-col">
        <p class="text-red-600 text-xl">
          There are {{ missingQuestions }} incomplete questions
        </p>
        <button class="btn-primary mt-4" (click)="showOnlyMissing()">
          Complete Missing Questions
        </button>
      </div>
    </div>
    <button *ngIf="!showSummaryInformation" class="btn-primary px-0 md:hidden mt-3 w-full" (click)="switchShowSidePanel()">Overview</button>
  </div>

  <div *ngIf="currentData.Questions.length > 0" [ngClass]="{
      ' hidden md:flex': !showSidePanel,
      '2xl:w-1/5 xl:w-1/3 lg:w-1/3 w-full h-[calc(100vh-3rem)] lg:h-[100vh]':
        showSidePanel
    }" class="pt-10 pb-5 ml-5">
    <app-audit-contents [currentQuestion]="currentQuestion" [currentQuestions]="currentData.Questions"
      (questionGUID)="jumpToQuestion($event)" (summaryDisplayedChange)="goToSummary()" [(summaryDisplayed)]="reloadedAudit" [(showSidePanel)]="showSidePanel"
      [(sidePanelUser)]="sidePanelUserChange"></app-audit-contents>
  </div>
</div>