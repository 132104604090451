import { BasicResult } from "../basic-result";

export class ProgramFeatures extends BasicResult{
    public TaskHopper:boolean = false;
    public Diary:boolean = false;
    public Reporting:boolean = false;
    constructor(values: Object = {}) {
        super();
        Object.assign(this, values);
    }
}