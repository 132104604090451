<div class="lg:px-5 px-2 my-2 ">
    <h1 *ngIf="showFilters" class="p-4">Reporting</h1>
    <div *ngIf="showFilters" class="mx-5 mt-4">
        <div class=" mb-2 border-grey-500 border-[1px] rounded-md p-3">
            <p class="text-lg hover:cursor-pointer" (click)="auditFilterSwitch()">Audits</p>
            <div *ngIf="!collapseAuditFilter">
                <div class="flex-col flex lg:flex-row justify-between">
                    <select class="text-box w-full md:max-w-xl mt-2 mx-2 standardTextSize"
                        [(ngModel)]="SelectedAuditType" (change)="updateAudits()">
                        <option *ngFor="let at of AuditTypes" [ngValue]="at">{{at.Description}}</option>
                    </select>
                    <select class="text-box w-full md:max-w-xl mx-2 lg:mx-0 mt-2 standardTextSize"
                        [(ngModel)]="SelectedFrequency" (change)="updateAudits()">
                        <option *ngFor="let f of Frequencies" [ngValue]="f">{{f.Description}}</option>
                    </select>
                </div>
                <select class="text-box w-full md:max-w-xl lg:mt-3 mt-2 mx-2 standardTextSize"
                    [(ngModel)]="SelectedAudit">
                    <option *ngFor="let a of DisplayAudits " [ngValue]="a">{{a.AuditName}}</option>
                </select>
            </div>
        </div>
        <div class=" mb-2 border-grey-500 border-[1px] rounded-md p-3">
            <p class="text-lg hover:cursor-pointer" (click)="locationFilterSwitch()">Locations</p>
            <div *ngIf="!collapseLocationFilter" class="flex flex-col lg:flex-row justify-between ml-2 mt-2">
                <select class="text-box w-full md:max-w-xl mt-2 lg:mt-0 standardTextSize"
                    [(ngModel)]="SelectedLocation">
                    <option *ngFor="let l of Locations" [ngValue]="l">{{l.LocationName}}</option>
                </select>
                <div class="flex justify-start  items-center mt-4 lg:mt-0 lg:ml-4">
                    <p>Include Sublocations:</p><app-toggle-switch [(checked)]="IncludeSubLocations"
                        class="ml-2"></app-toggle-switch>
                </div>
            </div>
        </div>
        <div class=" mb-2 border-grey-500 border-[1px] rounded-md p-3">
            <p class="text-lg hover:cursor-pointer" (click)="optionsFilterSwitch()">Options</p>
            <div *ngIf="!collapseOptionsFilter">
                <div class="flex mt-2">
                    <label class="mr-2">
                        <p>Start Date:</p>
                        <input [owlDateTime]="dt1" class="text-box mx-2" [(ngModel)]="StartDate"
                            [owlDateTimeTrigger]="dt1" />
                        <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                    </label>
                    <label class="mr-4">
                        <p>End Date:</p>
                        <input [owlDateTime]="dt2" class="text-box mx-2" [(ngModel)]="EndDate"
                            [owlDateTimeTrigger]="dt2" />
                        <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                    </label>
                </div>
                <div class="flex-col justify-between w-full px-2 lg:w-[50%]">
                    <select class="text-box w-full md:max-w-xl mt-2 standardTextSize" [(ngModel)]="SelectedReport"
                        (change)="updateReportControls()">
                        <option *ngFor="let r of Reports" [ngValue]="r">{{r.ReportName}}</option>
                    </select>
                </div>
                <div class="mt-3 mx-3 text-sm text-grey-900">
                    <p>{{SelectedReport.ReportSummary}}</p>
                </div>
            </div>
        </div>
        <!--<label>
            <strong>Start Date:</strong>
            <input [owlDateTime]="dt1" class="text-box lg:w-2/5 mx-2" [(ngModel)]="StartDate"
                [owlDateTimeTrigger]="dt1" />
            <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
        </label>
        <label>
            <strong>End Date:</strong>
            <input [owlDateTime]="dt2" class="text-box lg:w-2/5 mx-2" [(ngModel)]="EndDate"
                [owlDateTimeTrigger]="dt2" />
            <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
        </label>
        <select class="text-box w-full md:max-w-xl mt-2 standardTextSize" [(ngModel)]="SelectedFrequency"
            (change)="updateAudits()">
            <option *ngFor="let f of Frequencies" [ngValue]="f">{{f.Description}}</option>
        </select>
        <select class="text-box w-full md:max-w-xl mt-2 standardTextSize" [(ngModel)]="SelectedAuditType"
            (change)="updateAudits()">
            <option *ngFor="let at of AuditTypes" [ngValue]="at">{{at.Description}}</option>
        </select>
        <select class="text-box w-full md:max-w-xl mt-2 standardTextSize" [(ngModel)]="SelectedAudit">
            <option *ngFor="let a of DisplayAudits " [ngValue]="a">{{a.AuditName}}</option>
        </select>
        <select class="text-box w-full md:max-w-xl mt-2 standardTextSize" [(ngModel)]="SelectedReport"
            (change)="updateReportControls()">
            <option *ngFor="let r of Reports" [ngValue]="r">{{r.ReportName}}</option>
        </select>
        <select class="text-box w-full md:max-w-xl mt-2 standardTextSize" [(ngModel)]="SelectedLocation">
            <option *ngFor="let l of Locations" [ngValue]="l">{{l.LocationName}}</option>
        </select>-->
        <div class="flex flex-col lg:flex-row lg:justify-between">
            <button (click)="getReport()" [disabled]="FetchingReport" class="btn-primary mt-3">
                <span *ngIf="FetchingReport">Loading...</span>
                <span *ngIf="!FetchingReport">Load Report</span>
            </button>
            <button (click)="exportSelectedReport()" [disabled]="FetchingExport" class="btn-inverse mt-3">
                <span *ngIf="FetchingExport">Loading...</span>
                <span *ngIf="!FetchingExport">Export</span>
            </button>
        </div>

    </div>
    <div class="all-reports p-2 " [ngClass]="{'lg:p-4 mt-6':showFilters}">
        <div *ngIf="!showFilters" class="flex justify-between">
            <button (click)="returnToSummary()" class="btn-inverse mt-4">Return to Summary</button>
            <button (click)="exportSelectedReport()" class="btn-inverse mt-4">Export</button>
        </div>
        <div *ngIf="DisplayNonComplianceRows.length>0 && this.SelectedReport.ID == 1 ">

            <div *ngIf="!showNonComplianceDetails">


                <table class="w-full table-auto bg-standardwhite border border-grey-900 report-table">
                    <thead>
                        <tr class="border-b-[1px] border-green-700 ">
                            <th (click)="orderNCByAuditName()" class="text-left  pl-2 py-2 hover:cursor-pointer">Audit
                            </th>
                            <th (click)="orderNCByQuestion()" class="text-left  p-1 hover:cursor-pointer">Question</th>
                            <th (click)="orderNCByNonCompliance()" class="text-right p-1 hover:cursor-pointer">
                                Non-Compliances
                            </th>
                            <th (click)="orderNCByCompletedAudits()" class="text-right  p-1 hover:cursor-pointer">Total
                                Questions
                            </th>
                            <th (click)="orderNCByPercentage()" class="text-right  p-1 pr-2 hover:cursor-pointer">%</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let r of DisplayNonComplianceRows" (click)="selectNonComplianceRow(r)"
                            class="border-b-[1px] border-grey-300 hover:bg-grey-100 hover:cursor-pointer">
                            <td class="text-left  p-2">{{r.Info.AuditName}}</td>
                            <td class="text-left  p-2">{{r.Info.QuestionHeader}}</td>
                            <td class="text-right p-2">{{r.NonCompliantCounter}}</td>
                            <td class="text-right p-2">{{r.CompletedQuestionsCounter}}</td>
                            <td class="text-right  p-2">{{r.CompliancePerc}}</td>
                        </tr>
                    </tbody>

                </table>
            </div>
            <div *ngIf="showNonComplianceDetails" class="">
                <div class="lg:mx-3">

                    <div class="mb-3 mt-5">
                        <h3>{{selectedNonComplianceRow.Info.AuditName}}</h3>
                        <h5>{{selectedNonComplianceRow.Info.QuestionHeader}}</h5>
                    </div>

                    <table class="w-full table-auto bg-standardwhite border border-grey-900 p-2 mt-3 report-table">
                        <thead>
                            <tr class="border-b border-green-700">
                                <th class="text-left p-2">Audit Date</th>
                                <th class="text-left p-2">Location</th>
                                <th class="text-left p-2">Action Details</th>
                                <th class="text-left p-2">Action Status</th>
                                <th class="text-left p-2">Owner/Closed By</th>
                                <th class="text-right p-2">Due By/Closed On</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let r of selectedNonComplianceRow.SubNonComplianceRows">
                                <tr *ngFor="let sr of r.Actions; let first = first; let last = last"
                                    class="border-t-[1px] border-grey-300"
                                    [ngClass]="{'border-t-2 border-grey-900':first}">
                                    <td *ngIf="first" [attr.rowspan]="r.Actions.length || 1"
                                        class="text-left pt-1 pl-1 standardTextSize">
                                        {{basicDate(r.Info.DateRecorded)}}</td>
                                    <td *ngIf="first" [attr.rowspan]="r.Actions.length || 1"
                                        class="text-left  pt-1 pl-1 standardTextSize">
                                        {{r.Info.LocationName}}</td>
                                    <td class="text-left  pt-1 pl-1 border-l-[1px] border-grey-300 standardTextSize">
                                        {{sr.ActionDescription}}</td>
                                    <td class="text-left  pt-1 pl-1 standardTextSize">
                                        {{noncomplianceStatus(sr.Resolved)}}
                                    </td>
                                    <td class="text-left  pt-1 pl-1 standardTextSize">
                                        {{noncomplianceActionDisplayName(sr)}}
                                    </td>
                                    <td class="text-right  pt-1 pr-1 standardTextSize">{{noncomplianceActionDate(sr)}}
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>
        <div *ngIf="DisplayAuditCompletionRows.length>0 && this.SelectedReport.ID == 2 ">

            <div *ngIf="!showAuditCompletionDetails">
                <table class="w-full table-auto bg-standardwhite border border-grey-900 report-table">
                    <thead>
                        <tr class="border-b-[1px] border-green-700 ">
                            <th class="text-left p-2 hover:cursor-pointer" (click)="orderAuditCompletionByAuditName()">
                                Audit
                                Name</th>
                            <th class="text-left p-2 hover:cursor-pointer" (click)="orderAuditCompletionByFrequency()">
                                Frequency</th>
                            <th class="text-right p-2 hover:cursor-pointer"
                                (click)="orderAuditCompletionByCompletedAudits()">Completed Audits
                            </th>
                            <th class="text-right p-2 hover:cursor-pointer"
                                (click)="orderAuditCompletionByExpectedAudits()">Expected Audits
                            </th>
                            <th class="text-right p-2 hover:cursor-pointer"
                                (click)="orderAuditCompletionByCompletionPerc()">% On Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let r of DisplayAuditCompletionRows" (click)="selectActionCompletionRow(r)"
                            class="border-b-[1px] border-grey-300 hover:bg-grey-100 hover:cursor-pointer">
                            <td class="text-left p-2">{{r.Info.AuditName}}</td>
                            <td class="text-left p-2">{{frequencyDisplay(r.Info.FrequencyDescription,r.Info.FrequencyMultiplier)}}</td>
                            <td class="text-right p-2">{{r.completedAudits}}</td>
                            <td class="text-right p-2">{{r.expectedTotalAudits}}</td>
                            <td class="text-right p-2">{{r.percAuditsCompleted}}</td>
                        </tr>
                    </tbody>

                </table>

            </div>
            <div *ngIf="showAuditCompletionDetails">

                <div class="lg:mx-3">
                    <div class="flex-col flex lg:flex-row lg:justify-between">
                        <div class="mb-3 mt-5">
                            <h3>{{selectedAuditCompletionRow.Info.AuditName}}</h3>
                            <h5>Audit Frequency: {{frequencyDisplay(selectedAuditCompletionRow.Info.FrequencyDescription,selectedAuditCompletionRow.Info.FrequencyMultiplier)}}</h5>
                        </div>
                        <div class="flex justify-start my-3 items-center">
                            <strong>Show Missed Audits Only:</strong><app-toggle-switch
                                [(checked)]="showOnlyAuditCompletionIssueRows"
                                (checkedChange)="auditCompletedShowOnlyIssue()" class="ml-2"></app-toggle-switch>
                        </div>
                    </div>
                    <table class="w-full table-auto bg-standardwhite border border-grey-900 p-2 mt-3 report-table">
                        <thead>
                            <tr class="border-b border-green-700">
                                <th class="text-left p-2">Location</th>
                                <th class="text-left p-2">Period</th>
                                <th class="text-right p-2"># Audits</th>
                                <th class="text-center p-2">Successfully Completed</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let r of selectedAuditCompletionRow.subRows">
                                <tr *ngFor="let sr of auditCompletionDetails(r.Details); let first = first; let last = last"
                                    class="border-t-[1px] border-grey-300"
                                    [ngClass]="{'border-t-2 border-grey-900':first}">
                                    <td *ngIf="first" [attr.rowspan]="auditCompletionDetails(r.Details).length || 1"
                                        class="text-left pt-1 pl-1 standardTextSize">
                                        {{r.Info.LocationName}}</td>
                                    <td class="text-left  pt-1 pl-1 standardTextSize">
                                        {{auditPeriod(r.Info.FrequencyID,sr.PeriodStart,sr.PeriodEnd)}}</td>
                                    <td class="text-right  pt-1 pl-1 standardTextSize">{{sr.TotalCompleted}}
                                    </td>
                                    <td class="pt-1 px-1 standardTextSize text-center"><span
                                            class="material-symbols-outlined text-green-300 cursor-pointer pr-2"
                                            *ngIf="sr.CompletedInPeriod">check</span><span *ngIf="!sr.CompletedInPeriod"
                                            class="material-symbols-outlined text-red-600 cursor-pointer pr-2">close</span>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="NoData">
            <h3>No Report Data</h3>
            <p>There was no information available with the selected parameters, try to reduce the number of filters
                applied
                or widen the date range.</p>
        </div>
    </div>

</div>