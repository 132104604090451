export class ReportRequest{
    public ReportID:number = -1;
    public LocationIDs:number[] = [];
    public AuditIDs:number[]=[];
    public UserID:number[]=[];
    public AuditTypeID:number=-1;
    public HasStartDate:boolean = false;
    public StartDate:Date;
    public HasEndDate:boolean = false;
    public EndDate:Date;
    public IncludeChildren:boolean = false;
    public FrequencyID:number = -1;
}